import axios from 'axios';
import {
  ReviewConfiguration,
  reviewConfigurationFactory,
} from 'src/sundayplus/reviews/browse/domain/ReviewConfigurationFactory';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

export class HttpReviewConfigurationRepository {
  constructor(private readonly venueFeedbackBaseUrl: string) {}

  async get(businessId: String): Promise<ReviewConfiguration> {
    return axios
      .get(`${this.venueFeedbackBaseUrl}/businesses/${businessId}/review-configurations`)
      .then((response) => response.data)
      .then((data) => reviewConfigurationFactory(data.reviewsEnabled, data.externalSiteThreshold));
  }

  save(businessId: String, reviewConfiguration: ReviewConfiguration): Promise<{ success: boolean }> {
    const request = {
      businessId,
      reviewsEnabled: reviewConfiguration.reviewsEnabled,
      externalSiteThreshold: reviewConfiguration.externalSiteRatingThreshold,
    };

    return axios
      .post(`${this.venueFeedbackBaseUrl}/businesses/${businessId}/review-configurations`, request, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => ({ success: true }))
      .catch(() => ({ success: false }));
  }
}

export const reviewConfigurationRepository = new HttpReviewConfigurationRepository(
  ConfigurationLoader.load().venueFeedbackBaseUrl,
);
