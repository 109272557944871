// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';

/*
 Moment's methods in a functional way
 They can be referenced to in the 'clone' method usage in Instant
 */
const minutes = (m: number): (m: Moment) => Moment => (mm: Moment) => mm.minutes(m);
const seconds = (s: number): (m: Moment) => Moment => (m: Moment) => m.seconds(s);
const milliseconds = (ms: number): (m: Moment) => Moment => (m: Moment) => m.milliseconds(ms);
const add = (n: number, unitOfTime: UnitOfTime): (m: Moment) => Moment => (m: Moment) => m.add(n, unitOfTime);
const subtract = (n: number, unitOfTime: UnitOfTime): (m: Moment) => Moment => (m: Moment) => m.subtract(n, unitOfTime);
const startOf = (unitOfTime: 'd' | 'day' | 'M'): (m: Moment) => Moment => (m: Moment) => m.startOf(unitOfTime);
const endOfDay = (m: Moment) => m.endOf('day');

export type UnitOfTime = 'm' | 'minutes' | 'd' | 'day' | 'y' | 'M' | 'h' | 'hours';

export class Instant {
  private constructor(private time: Moment) {
    this.time = time.clone();
  }

  public static now() {
    return new Instant(moment.utc());
  }

  /**
   * @Deprecated we should not use js Date anymore
   * we need to adapt the code using this
   * see ADR : https://www.notion.so/sundayapp/Date-handling-in-merchant-dashboard-1744779213d345fcb283f8a9930a13cc
   */
  public static fromJsDate(date?: Date) {
    return new Instant(moment.utc(date));
  }

  private static uptoTwoChar(initial: number): string {
    const temp = '00' + String(initial);
    return temp.substring(temp.length - 2, temp.length);
  }

  private static specialFormat(date: Date): string {
    return this.uptoTwoChar(date.getDate()) + '/'
      + this.uptoTwoChar(date.getMonth() + 1) + '/'
      + date.getFullYear() + 'T'
      + this.uptoTwoChar(date.getHours()) + ':'
      + this.uptoTwoChar(date.getMinutes()) + ':'
      + this.uptoTwoChar(date.getSeconds());
  }

  /**
   * @Deprecated we should not use js Date anymore
   * we need to adapt the code using this
   * see ADR : https://www.notion.so/sundayapp/Date-handling-in-merchant-dashboard-1744779213d345fcb283f8a9930a13cc
   */
  public static fromDateWithTimezone(date: Date, timezone: string) {
    return new Instant(moment.tz(this.specialFormat(date), 'DD/MM/YYYY, hh:mm:ss', timezone));
  }

  public static fromString(date?: string, format?: string) {
    return new Instant(moment.utc(date, format));
  }

  static fromEpoch(date: number) {
    if (date <= 1000000000000) {
      return new Instant(moment.utc(date * 1000));
    }
    return new Instant(moment.utc(date));
  }

  private clone(mapper: (m: Moment) => Moment) {
    return new Instant(mapper(this.time.clone()));
  }

  format(format?: string) {
    return this.time.format(format);
  }

  toISOString() {
    return this.time.toISOString();
  }

  unixInMs() {
    return this.time.valueOf();
  }

  hours(h: number): Instant {
    return this.clone((m) => moment.utc(m.utc(false))
      .hours(h));
  }

  minutes(m: number): Instant {
    return this.clone(minutes(m));
  }

  seconds(s: number): Instant {
    return this.clone(seconds(s));
  }

  millisecond(ms: number): Instant {
    return this.clone(milliseconds(ms));
  }

  add(n: number, unitOfTime: UnitOfTime): Instant {
    return this.clone(add(n, unitOfTime));
  }

  subtract(n: number, unitOfTime: UnitOfTime): Instant {
    return this.clone(subtract(n, unitOfTime));
  }

  startOf(unitOfTime: 'd' | 'day' | 'M') {
    return this.clone(startOf(unitOfTime));
  }

  endOfDay() {
    return this.clone(endOfDay);
  }

  /**
   * @Deprecated we should not use js Date anymore
   * use Instant instead
   * see ADR : https://www.notion.so/sundayapp/Date-handling-in-merchant-dashboard-1744779213d345fcb283f8a9930a13cc
   */
  toDate() {
    return this.time.toDate();
  }

  isBefore(other: Instant) {
    return this.time.isSameOrBefore(other.time);
  }

  deltaInDays(to: Instant): number {
    return Math.abs(Math.round(this.time.diff(to.time, 'days', true)));
  }

  public equals(other: Instant): boolean {
    return this.unixInMs() === other.unixInMs();
  }
}
