import React, { ReactNode } from 'react';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import { Cell, ChildCell, ChildLink, HeaderCell, Line, Table } from './DataGrid.style';
import { RightDownArrowIcon } from '../Icons/RightDownArrowIcon';
import { colorPalette } from '../../../stylesheet';

export enum DataGridAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface Column<T> {
  id: string;
  label?: ReactNode;
  renderCell?: (row: T) => ReactNode;
  align?: DataGridAlign;
}

interface ObjectWithId {
  id: string;
}

interface Props<T extends ObjectWithId> {
  columns: Column<T>[];
  rows: T[];
  noHeader?: boolean;
  selectedItem?: T;
  onLineClick?: (value: T) => void;
  getSubItems?: (value: T) => T[];
}

function DataGrid<T extends ObjectWithId>({
  columns,
  rows,
  noHeader,
  selectedItem,
  onLineClick,
  getSubItems,
  ...props
}: Props<T>) {
  // eslint-disable-next-line react/prop-types
  const dataTestId = (props as never)['data-testid'];

  return (
    <Table {...props}>
      {!noHeader && (
        <thead>
          <Line>
            {columns.map((column) => (
              <HeaderCell key={column.id} align={column.align || DataGridAlign.LEFT}>
                {column.label || column.id}
              </HeaderCell>
            ))}
          </Line>
        </thead>
      )}
      <tbody>
        {rows.map((row) => (
          <>
            <Line
              key={row.id}
              selected={selectedItem && selectedItem.id === row.id}
              onClick={onLineClick ? () => onLineClick(row) : undefined}
              data-testid={`${dataTestId}-item`}
            >
              {columns.map((column) => (
                <Cell key={column.id} align={column.align || DataGridAlign.LEFT}>
                  {column.renderCell ? column.renderCell(row) : (row as never)[column.id]}
                </Cell>
              ))}
            </Line>
            {getSubItems &&
              getSubItems(row).length > 0 &&
              getSubItems(row).map((subRow, rowIndex) => (
                <Line
                  key={subRow.id}
                  selected={selectedItem && selectedItem.id === subRow.id}
                  onClick={onLineClick ? () => onLineClick(subRow) : undefined}
                  data-testid={`${dataTestId}-item`}
                >
                  {columns.map((column, columnIndex) => (
                    <>
                      {columnIndex === 0 ? (
                        <ChildCell
                          key={column.id}
                          align={column.align || DataGridAlign.LEFT}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <ChildLink isFirst={rowIndex === 0} />
                          <RightDownArrowIcon style={{ marginRight: spaceUsages.small }} color={colorPalette.grey600} />
                          {column.renderCell ? column.renderCell(subRow) : (subRow as never)[column.id]}
                        </ChildCell>
                      ) : (
                        <Cell key={column.id} align={column.align || DataGridAlign.LEFT}>
                          {column.renderCell ? column.renderCell(subRow) : (subRow as never)[column.id]}
                        </Cell>
                      )}
                    </>
                  ))}
                </Line>
              ))}
          </>
        ))}
      </tbody>
    </Table>
  );
}

export default DataGrid;
