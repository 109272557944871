import React, { ChangeEvent, createRef, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { loadFileAsImage, loadImage } from '../ImageUploaderWithDimensions/service';
import { PictureManipulator } from './PictureManipulator';
import { alpha, Avatar, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { UploadFileIcon } from 'src/ordering/common/components/Icons/UploadFileIcon';

type PicturePickerProps = {
  imageUrl: string | null;
  onImageChanged: (content: string) => void;
  updating: boolean;
  width: number;
  height: number;
  exportWidth?: number;
  exportHeight?: number;
  disabled?: boolean;
};

type PictureUploaderButtonProps = {
  width: number;
  height: number;
};

const PictureUploaderButton = styled.div<PictureUploaderButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

const UploadIconContainer = styled(Avatar)`
  background-color: ${alpha(themeV5.palette.primary.main, 0.12)};
`;

const ClickDragDrop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  line-height: 20px;
  letter-spacing: -0.5px;
`;
export const MenuPicturePicker = ({
  imageUrl,
  onImageChanged,
  updating,
  width,
  height,
  exportWidth = width,
  exportHeight = height,
  disabled = false,
}: PicturePickerProps) => {
  const inputRef = createRef<HTMLInputElement>();
  const [image, setImage] = useState<HTMLImageElement | undefined>();
  const [editing, makeEditing] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(1);

  const onImageContentChanged = (content: string) => {
    try {
      onImageChanged(content);
    } finally {
      makeEditing(false);
    }
  };

  const startUploadFile = useCallback(() => {
    if (inputRef.current && !updating && !editing) {
      inputRef.current!.value = '';
      inputRef.current.click();
    }
  }, [inputRef, updating, editing]);

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      loadFileAsImage(file).then(setImage);
      makeEditing(true);
    }
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      loadFileAsImage(file).then(setImage);
      makeEditing(true);
    }
  };

  const initImage = useCallback((imageUrlToInit: string | null) => {
    if (!imageUrlToInit) {
      setImage(undefined);
      return;
    }

    loadImage(imageUrlToInit).then((initializedImage) => {
      setImage(initializedImage);
    });
  }, []);

  useEffect(() => {
    initImage(imageUrl);
  }, [imageUrl, initImage]);

  const onCancel = () => {
    initImage(imageUrl);
    makeEditing(false);
  };

  return (
    <>
      {(imageUrl || image) ? (
        <PictureManipulator
          image={image}
          imageUrl={imageUrl}
          zoom={zoom}
          setZoom={setZoom}
          width={width}
          height={height}
          exportWidth={exportWidth}
          exportHeight={exportHeight}
          onUploadNewFile={startUploadFile}
          isEditMode={editing}
          onImageContentChanged={onImageContentChanged}
          onCancel={onCancel}
          disabled={disabled}
        />
      ) : (
        <PictureUploaderButton
          onClick={startUploadFile}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
          width={width} height={height}>
          <UploadIconContainer><UploadFileIcon /></UploadIconContainer>
          <ClickDragDrop>
            <Typography color={'primary'} style={{ textDecoration: 'underline' }}>
              <FormattedMessage id="picture-picker.add-image" />
            </Typography>
            <Typography>
              <FormattedMessage id="picture-picker.add-image.drag-drop" />
            </Typography>
          </ClickDragDrop>
          <Typography style={{ color: themeV5.palette.action.active, fontSize: '14px' }}>
            <FormattedMessage id="picture-picker.add-image.format-size" />
          </Typography>
        </PictureUploaderButton>
      )}

      <HiddenInput type="file" name="product-image" onChange={onFileChange} ref={inputRef} accept="image/*" />
    </>
  );
};
