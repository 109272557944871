// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { Translation } from '../../common/translation';
import { datePresets } from 'src/components/time-frame-filter/datePresets';

export type DateRange = {
  startDate: Moment;
  endDate: Moment;
};

export const revenueTabDatePresets = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7_DAYS_FILTER: 'LAST_7_DAYS_FILTER',
  LAST_WEEK_FILTER: 'LAST_WEEK_FILTER',
  MONTH_TO_DATE_FILTER: 'MONTH_TO_DATE_FILTER',
  LAST_MONTH_FILTER: 'LAST_MONTH_FILTER',
  CURRENT_YEAR_FILTER: 'CURRENT_YEAR_FILTER',
  PREVIOUS_YEAR_FILTER: 'PREVIOUS_YEAR_FILTER',
  CUSTOM: 'CUSTOM',

} as const;

export type RevenueTabDatePreset = ValuesOf<typeof revenueTabDatePresets>;

export type Period = {
  filter: string;
  dateRange: DateRange;
};

export type RevenuePeriod = Period & {
  filter: RevenueTabDatePreset;
  dateRange: DateRange;
};
export const revenuesDatePresets: (timezone?: string, momentProvider?: () => Moment) => Record<RevenueTabDatePreset, {
  range: DateRange,
  translation: Translation,
}> = (
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  momentProvider = () => moment(),
) => datePresets(timezone, momentProvider);
