import { Instant } from '../../../../Instant';

const formatDatetime = (locale: string) => {
  if (locale.startsWith('fr')) {
    return 'DD/MM/YYYY HH:mm';
  }

  if (locale.startsWith('en-US')) {
    return 'ddd, MMMM DD, YYYY - HH:mm';
  }

  if (locale.startsWith('en')) {
    return 'ddd, DD MMMM YYYY, HH:mm';
  }

  return 'YYYY-MM-DD HH:mm';
};

const formatDate = (locale: string) => {
  if (locale.startsWith('fr')) {
    return 'DD/MM/YYYY';
  }

  if (locale.startsWith('en-US')) {
    return 'ddd, MMMM DD, YYYY';
  }

  if (locale.startsWith('en')) {
    return 'ddd, DD MMMM YYYY';
  }

  return 'YYYY-MM-DD';
};

export const formatCreationDateTime = (creationDate: number, locale: string) => Instant.fromEpoch(creationDate)
  .format(formatDatetime(locale));

export const formatCreationDate = (creationDate: number, locale: string) => Instant.fromEpoch(creationDate)
  .format(formatDate(locale));
