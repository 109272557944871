import { TextFieldProps } from '@mui/material/TextField/TextField';
import { LocalisationKey } from 'src/lang/en';
import { UseFormRegisterReturn } from 'react-hook-form';

import { TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';

type TextFieldWithOptionsProps = Omit<TextFieldProps, 'label' | 'placeholder'> & {
  labelKey: LocalisationKey,
  placeholderKey: LocalisationKey,
  helperText?: string,
  registerProps: UseFormRegisterReturn,
};

export function TextFieldWithLabel({ name, labelKey, placeholderKey, helperText, registerProps, ...textFieldProps }: TextFieldWithOptionsProps) {
  const intl = useIntl();
  return <TextField
    label={<FormattedMessage id={labelKey} />}
    placeholder={intl.formatMessage({ id: placeholderKey })}
    fullWidth
    variant="standard"
    error={!!helperText}
    helperText={helperText}

    {...registerProps}
    {...textFieldProps}
  />;
}
