import React from 'react';
import { CircularProgress, Stack } from '@mui/material';
import GuestConsentCSVExporterButton from './GuestConsentCSVExporterButton';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { CustomerData } from '../domain/CustomerData';
import { ConsentRepositoryHttp } from '../infrastructure/ConsentRepositoryHttp';
import { GuestConsentDataGrid } from '../components/GuestConsentDataGrid';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ConsentCollectionToggle } from 'src/marketing/customer-data/components/ConsentCollectionToggle';
import { useGuestDataCollectionQuery } from 'src/marketing/customer-data/hooks/useGuestDataCollectionQuery';
import { OptInLevelDefinition } from '../components/OptInLevelDefinition';
import { useAsync } from 'react-use';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { Pagination } from 'src/menu/dynamic-menu/pages/ProductsPage/hooks/useSearchProductsFilters';

const configuration = ConfigurationLoader.load();
const repository = new ConsentRepositoryHttp(configuration.consentManagerBaseUrl);

export const CustomerDataPage = () => {
  const queryClient = useQueryClient();
  const { id: businessId, name } = useCurrentBusinessOrThrow();
  const { data: consentCollection, isLoading, isFetching } = useGuestDataCollectionQuery(businessId);
  const [pagination, setPagination] = React.useState<Pagination>({ pageSize: 10, page: 0 });

  const updateConsentCollection = async (active: boolean) => {
    if (active) await repository.activateGuestDataCollection(businessId);
    if (!active) await repository.deactivateGuestDataCollection(businessId);
    await queryClient.invalidateQueries({ queryKey: [queryKeys.GUEST_DATA_COLLECTION, businessId] });
  };

  const { value: data, loading: loadingCustomerData } = useAsync(async (): Promise<{
    customerData: CustomerData[];
    count: number;
  }> => {
    if (!consentCollection || !consentCollection.active) return { customerData: [], count: 0 };
    return repository.listVenueConsent(businessId, pagination.pageSize, pagination.page * pagination.pageSize);
  }, [consentCollection, pagination, businessId]);

  const { value: allCustomerData } = useAsync(async (): Promise<{
    customerData: CustomerData[];
  }> => {
    if (!consentCollection || !consentCollection.active) {
      return { customerData: [] };
    }
    return repository.listAllVenueConsent(businessId);
  }, [consentCollection, businessId]);

  if (isLoading || !consentCollection) return <CircularProgress color={'secondary'} />;

  return (
    <Stack spacing={2}>
      <ConsentCollectionToggle
        toggleActivated={consentCollection.active}
        toggleConsentCollection={updateConsentCollection}
        processing={isFetching}
      />

      {consentCollection.active && (
        <>
          <OptInLevelDefinition />
          <GuestConsentDataGrid
            customerData={data?.customerData}
            loading={loadingCustomerData}
            onPaginationChanged={(p) => {
              setPagination(p);
            }}
            pageSize={pagination.pageSize}
            total={data?.count || 0}
          />
          <GuestConsentCSVExporterButton venueName={name} customerDataList={allCustomerData?.customerData || []} />
        </>
      )}
    </Stack>
  );
};
