import { useFormContext } from 'react-hook-form';
import { Alert, Stack, Typography } from '@mui/material';
import {
  PersonalIdentityType,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ProofOfIdentity/PersonalIdentityType';
import React, { useEffect } from 'react';
import {
  UploadFrontDocument,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ProofOfIdentity/UploadFrontDocument';
import {
  UploadBackDocument,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ProofOfIdentity/UploadBackDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const ProofOfIdentity = ({ representativeIndex }: { representativeIndex: number }) => {
  const { watch, unregister } = useFormContext<CheckoutOnboardingInformationDraft>();

  const personalIdType = watch(`representatives.${representativeIndex}.identificationType`);
  const shouldUploadFrontDocument = !!personalIdType;
  const shouldUploadBackDocument = !!personalIdType && personalIdType !== 'passport';

  useEffect(() => {
    if (!shouldUploadBackDocument) {
      unregister(`representatives.${representativeIndex}.identificationBackDocument`);
    }
  }, [shouldUploadBackDocument]);

  return (
    <Stack spacing={3}>
      <Typography variant={'h6'}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.identification'} />
      </Typography>
      <Alert severity="info">
        <Typography>
          <FormattedMessage id={'settings.payments.checkout.onboarding.proof_of_identity.helper_text'} />
        </Typography>
      </Alert>
      <PersonalIdentityType legalRepresentativeIndex={representativeIndex} />
      {shouldUploadFrontDocument && <UploadFrontDocument legalRepresentativeIndex={representativeIndex} />}
      {shouldUploadBackDocument && <UploadBackDocument legalRepresentativeIndex={representativeIndex} />}
    </Stack>
  );
};
