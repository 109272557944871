import { UserRole, userRoles } from './UserRole';
import { BusinessId } from 'src/business/domain/Business';

export type Role = {
  businessId: BusinessId;
  businessName: string;
  userRole: UserRole;
};

export const roleForBusiness = (claims: Array<Role>, businessId: BusinessId): UserRole | undefined =>
  claims.find((claim) => claim.businessId === businessId)?.userRole;

export const isAuthorized = (claims: Array<Role>, authorizedRoles: UserRole[], businessId: BusinessId): boolean =>
  !!claims.find((claim) => claim.businessId === businessId && authorizedRoles.includes(claim.userRole));

export const hasAccessToRefund = (claim?: Role): boolean =>
  !!claim && !![userRoles.ADMIN, userRoles.GENERAL_MANAGER].find((r) => r === claim.userRole);
