import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { CircularProgress, styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toViewModel } from './StaffPerformanceViewModel';
import { useStaffPerformance } from './useStaffPerformance';
import { Header } from '../component/DataGridHeader';
import { buildColumns } from './buildColumns';
import { NoDataOverlay } from '../component/NoDataOverlay';
import { buildGroupColumns } from './buildGroupColumns';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { StaffPerformanceRangeType } from 'src/staff-performance/domain/StaffPerformance';
import { useGetSinglePosConnectionOptions } from 'src/pos/queries/getPosConnectionOptions';
import { Instant } from 'src/sundayplus/Instant';

const StaffDataGrid = styled(DataGrid)`
  .MuiDataGrid-columnHeader--filledGroup {
    .MuiDataGrid-columnHeaderTitleContainer {
      border-bottom: none !important;
    }
  }

  .MuiDataGrid-overlayWrapper {
    z-index: 1;
  }
`;
const defaultRange: StaffPerformanceRangeType = '7_DAYS';
export const StaffPerformance = () => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const isUSBusiness = business.address.countryCode === 'US';
  const [startDate, updateStartDate] = useState<Instant>(Instant.now()
    .startOf('day'));
  const [endDate, updateEndDate] = useState<Instant>(Instant.now()
    .endOfDay());
  const [range, setRange] = useState<StaffPerformanceRangeType>(defaultRange);

  const {
    isLoading: isStaffPerformanceLoading,
    data,
  } = useStaffPerformance({
    range,
    businessId: business.id,
    timezone: business.timezone,
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
  });
  const {
    isLoading: isPosConnectionLoading,
    data: posConnectionOptions,
  } = useGetSinglePosConnectionOptions(business.id);
  const shouldCollectAdoptionRate = posConnectionOptions?.collectAdoptionRate ?? false;

  const isLoading = isStaffPerformanceLoading || isPosConnectionLoading;

  const onCustomRangeChanged = (currentStartDate: Date, currentEndDate: Date) => {
    updateStartDate(Instant.fromJsDate(currentStartDate));
    updateEndDate(Instant.fromJsDate(currentEndDate));
  };

  return (
    <>
      <Header
        range={range}
        setRange={setRange}
        startDate={startDate.toDate()}
        endDate={endDate.toDate()}
        setCustomRangeDate={onCustomRangeChanged}
      />
      {!isLoading && (
        <StaffDataGrid
          rows={toViewModel(data ?? [], intl.locale, business.currency, shouldCollectAdoptionRate)}
          columns={buildColumns(isUSBusiness, business)}
          disableRowSelectionOnClick
          autoHeight
          hideFooter
          columnHeaderHeight={28}
          slots={{
            noRowsOverlay: NoDataOverlay,
          }}
          columnGroupingModel={buildGroupColumns()}
        />
      )}
      {isLoading && <CircularProgress style={{ alignSelf: 'center' }} />}
    </>
  );
};
