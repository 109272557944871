import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, FormControl, InputLabel, Select, SelectChangeEvent, SxProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { LocalisationKey } from 'src/lang/en';
import {
  DatePresets,
  extractTimeFrameFilterFromURLParams,
  Period,
  URL_DATE_FORMAT,
} from 'src/components/time-frame-filter/TimeFrameFilter';
import { RevenueTabDatePreset, revenueTabDatePresets } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { DateTimePicker, IconButton } from '@sundayapp/b2b-react-component-library';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone';
import { datadogLogs } from '@datadog/browser-logs';

type PeriodFilterProps<T extends Record<string, string>> = {
  datePresets: DatePresets<T>;
  period: Period<ValuesOf<T>>;
  setPeriod: Dispatch<SetStateAction<Period<any>>>;
  timezone?: string;
  sx?: SxProps;
  label?: { key: LocalisationKey; defaultMessage: string };
};
const PERIOD_FILTER_WIDTH = 280;

export const TimeFrameFilter = function <T extends Record<string, string>>({
  datePresets,
  period,
  setPeriod,
  timezone,
  sx = {
    minWidth: PERIOD_FILTER_WIDTH,
  },
  label,
}: PeriodFilterProps<T>) {
  const intl = useIntl();
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);

  const periodFilterOptions = (Object.keys(datePresets) as ValuesOf<T>[]).map((key) => ({
    filter: key,
    translation: datePresets[key].translation,
  }));

  const updatePeriod = (urlParams: URLSearchParams, value: string, tabDataPresets: RevenueTabDatePreset) => {
    setPeriod(
      extractTimeFrameFilterFromURLParams<typeof revenueTabDatePresets>(
        urlParams.toString(),
        datePresets as DatePresets<typeof revenueTabDatePresets>,
        tabDataPresets,
      ),
    );
  };
  const onDatePresetChange = (event: SelectChangeEvent) => {
    const revenueDatePreset: string = event.target.value;
    if (revenueDatePreset === revenueTabDatePresets.CUSTOM) {
      setIsDatePickerOpened(true);
    } else {
      setIsDatePickerOpened(false);
      const urlParams = new URLSearchParams(location.search);
      urlParams.set('preset', revenueDatePreset);
      urlParams.delete('startDate');
      urlParams.delete('endDate');
      urlParams.delete('tz');
      updatePeriod(urlParams, revenueDatePreset, revenueTabDatePresets.LAST_7_DAYS_FILTER);
    }
  };

  const onDatePickerClosed = () => {
    setIsDatePickerOpened(false);
  };

  const onCustomDateRangeSelectionChange = (dates: Date[]) => {
    setIsDatePickerOpened(false);
    const datePickerStartDateInput = moment(dates[0]).format(URL_DATE_FORMAT);
    const datePickerEndDateInput = moment(dates[1]).format(URL_DATE_FORMAT);

    const urlParams = new URLSearchParams(location.search);
    urlParams.set('preset', revenueTabDatePresets.CUSTOM);
    urlParams.set('startDate', datePickerStartDateInput);
    urlParams.set('endDate', datePickerEndDateInput);
    if (timezone) {
      urlParams.set('tz', timezone);
    }
    updatePeriod(urlParams, 'CUSTOM', revenueTabDatePresets.CUSTOM);
  };

  const getCustomValues = () => {
    try {
      return [
        new Date(period.dateRange.startDate.clone().startOf('day').format('yyyy-MM-DD HH:mm')),
        new Date(period.dateRange.endDate.clone().endOf('day').format('yyyy-MM-DD HH:mm')),
      ];
    } catch (e) {
      datadogLogs.logger.error(
        'Error while parsing date range in TimeFrameFilter',
        {
          period,
          datePresets,
          timezone,
          location,
        },
        e as Error,
      );
      return [
        new Date(moment().startOf('day').format('yyyy-MM-DD HH:mm')),
        new Date(moment().endOf('day').format('yyyy-MM-DD HH:mm')),
      ];
    }
  };
  return (
    <FormControl>
      <Box display="flex" alignItems="center" flexDirection={'row'} gap={1}>
        <InputLabel id="payout-period-select-label">
          <FormattedMessage id={label?.key as LocalisationKey} defaultMessage={label?.defaultMessage} />
        </InputLabel>
        <Select
          labelId="invoice-period-select-label"
          id="payout-period-select"
          value={period.filter}
          label={<FormattedMessage id={label?.key as LocalisationKey} defaultMessage={label?.defaultMessage} />}
          onChange={(e) => onDatePresetChange(e)}
          sx={sx}
        >
          {periodFilterOptions.map((value) => (
            <MenuItem value={value.filter} key={value.filter}>
              {intl.formatMessage({ id: value.translation.id }, value.translation.values)}
            </MenuItem>
          ))}
        </Select>
        {period.filter === revenueTabDatePresets.CUSTOM && (
          <IconButton size="small" onClick={() => setIsDatePickerOpened(!isDatePickerOpened)} variant="default">
            <CalendarMonthIcon direction="left" />
          </IconButton>
        )}
        {isDatePickerOpened && (
          <DateTimePicker
            onClose={onDatePickerClosed}
            onApply={onCustomDateRangeSelectionChange}
            value={getCustomValues()}
          />
        )}
      </Box>
    </FormControl>
  );
};
