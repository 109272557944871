import { Stack, Typography } from '@mui/material';
import { FirstName } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/FirstName';
import { LastName } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/LastName';
import {
  DateOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/DateOfBirth';
import {
  CountryOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/CountryOfBirth';
import React from 'react';
import {
  RepresentativeAddress,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/RepresentativeAddress';
import Divider from '@mui/material/Divider';
import {
  CompanyPosition,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/CompanyPosition';
import { ControlExtraRoles } from './ControlExtraRoles';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { isUs } from 'src/payments/components/Checkout/CheckoutOnboarding/IsUs';
import { Email } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/Email';
import {
  NationalIdNumberIfUS,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/NationalIdNumberIfUS';


export const ControlPersonalInfo = ({ representativeIndex, businessCountryCode }: {
  representativeIndex: number,
  businessCountryCode: string,
}) => {
  return <Stack spacing={3}>
    <Typography variant={'h6'}><FormattedMessage
      id={'settings.payments.checkout.onboarding.representatives.personalInformation'} /></Typography>
    <Stack spacing={3} direction={'row'}>
      <FirstName legalRepresentativeIndex={representativeIndex} />
      <LastName legalRepresentativeIndex={representativeIndex} />
    </Stack>
    <ControlExtraRoles representativeIndex={representativeIndex} businessCountryCode={businessCountryCode} />
    <DateOfBirth legalRepresentativeIndex={representativeIndex} />
    <CountryOfBirth legalRepresentativeIndex={representativeIndex} />
    <NationalIdNumberIfUS businessCountryCode={businessCountryCode} representativeIndex={representativeIndex} />
    {isUs(businessCountryCode) && <Email representativeIndex={representativeIndex} />}
    <RepresentativeAddress representativeIndex={representativeIndex} />
    <Stack spacing={3}>
      <Divider />
      <CompanyPosition representativeIndex={representativeIndex} />
    </Stack>
  </Stack>;
};
