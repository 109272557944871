import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useQuery } from '@tanstack/react-query';
import { GlobalConfigurationRepositoryHttp } from './GlobalConfigurationRepositoryHttp';
import { GlobalConfiguration } from '../domain/GlobalConfiguration';
import { queryKeys } from 'src/app/queries/utils';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

type GlobalConfigurationSelector<T> = (globalConfiguration: GlobalConfiguration) => T;

export function useGlobalConfiguration<T = GlobalConfiguration>(selector?: GlobalConfigurationSelector<T>): UseQueryResult<T> {
  const business = useCurrentBusinessOrThrow();
  const globalConfigurationRepository = new GlobalConfigurationRepositoryHttp(
    ConfigurationLoader.load().globalConfigurationUrl,
  );
  return useQuery({
    queryKey: [queryKeys.GET_GLOBAL_CONFIGURATION, {
      businessId: business.id,
      countryCode: business.address.countryCode,
    }],
    queryFn: (): Promise<GlobalConfiguration> => {
      return globalConfigurationRepository.getGlobalConfiguration(business.id, business.address.countryCode);
    },
    select: selector,
  });
}
