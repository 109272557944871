import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { BusinessId } from 'src/business/domain/Business';
import {
  useFindProductsBySource,
  usePollProductsBySource,
} from 'src/menu/dynamic-menu/queries/product/getAllProductByBusinessIdQuery';
import { useRefreshProductsMutation } from 'src/menu/dynamic-menu/mutations/product/useRefreshProductsMutation';
import { ProductSummary } from 'src/menu/dynamic-menu/domain/ProductSummary';
import {
  canRefreshProductsFromPos,
  MenuAssetsSource,
  posesWithSyncProducts,
} from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import { useGetPosConnectionsWithRevenueCenters } from 'src/pos/queries/getPosConnectionsWithRevenueCenters';

type ProductSyncButtonProps = {
  businessId: BusinessId;
  menuAssetsSource: MenuAssetsSource | undefined;
};

export const DisabledRefreshButton = () => {
  return (
    <Box sx={{ opacity: 0.5 }}>
      <IconButton aria-label="delete" size="large" disabled>
        <CachedIcon fontSize="large" />
      </IconButton>
    </Box>
  );
};

function useRefreshProducts(businessId: BusinessId, menuAssetsSource: MenuAssetsSource) {
  const snackBar = useSnackbar();
  const intl = useIntl();
  const [isFetching, setIsFetching] = useState(false);
  const refreshProducts = useRefreshProductsMutation(businessId, menuAssetsSource);
  const { data: productsBeforeRefresh } = useFindProductsBySource(businessId, menuAssetsSource);
  const { data: fetchedProducts } = usePollProductsBySource(businessId, menuAssetsSource, isFetching);

  const stopFetching = () => setIsFetching(false);

  const detectProductsUpdate = (productsFetched: ProductSummary[] | undefined) => {
    if (productsFetched?.length !== productsBeforeRefresh?.length) return true;
    const newProducts = productsFetched?.filter(
      (updatedProduct) => !productsBeforeRefresh?.some((currentProduct) => updatedProduct.id === currentProduct.id),
    );
    return newProducts && newProducts?.length > 0;
  };

  useEffect(() => {
    if (fetchedProducts && detectProductsUpdate(fetchedProducts)) {
      setIsFetching(false);
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'menus.sync.products.finished.success' }),
      });
    }
  }, [fetchedProducts]);

  const onRefresh = async () => {
    if (isFetching) {
      stopFetching();
      return;
    }

    await refreshProducts.mutateAsync();
    setIsFetching(true);
    setTimeout(() => {
      stopFetching();
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'menus.sync.finished.no_update' }),
      });
    }, 60000);
  };
  return { isFetching, onRefresh };
}

export const EnabledRefreshButton = ({ businessId, menuAssetsSource }: ProductSyncButtonProps & {
  menuAssetsSource: MenuAssetsSource
}) => {
  const intl = useIntl();
  const { isFetching, onRefresh } = useRefreshProducts(businessId, menuAssetsSource);

  if (isFetching) {
    return (<Box display={'flex'} alignItems={'center'}>
      <Tooltip title={intl.formatMessage({ id: 'menus.products.sync.button.in-progress' })}>
        <CircularProgress color={'secondary'} size={30} />
      </Tooltip>
    </Box>);
  }

  return (
    <Box display={'flex'} onClick={onRefresh}>
      <Tooltip title={intl.formatMessage({ id: 'menus.products.sync.button' })}>
        <IconButton aria-label="delete" size="large">
          <CachedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const ProductRefreshButton = ({ businessId, menuAssetsSource }: ProductSyncButtonProps) => {

  const {
    data: posConnections,
    isLoading,
    isError,
  } = useGetPosConnectionsWithRevenueCenters(businessId);

  if (isLoading || isError) {
    return <DisabledRefreshButton />;
  }

  let somePosConnectionsDoNotSupportSyncProducts = posConnections && posConnections.some(connect => !posesWithSyncProducts.includes(connect.posReference));
  if (!menuAssetsSource || !canRefreshProductsFromPos(menuAssetsSource) || somePosConnectionsDoNotSupportSyncProducts) {
    return <DisabledRefreshButton />;
  }

  return <EnabledRefreshButton businessId={businessId} menuAssetsSource={menuAssetsSource} />;
};
