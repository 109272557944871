import React from 'react';
import {
  BanContactCardSvg,
  BimpliCardSvg,
  EdenredCardSvg,
  IconWrapper,
  IdealCardSvg,
  SodexoCardSvg,
  SwileCardSvg,
  TicketRestaurantCardSvg,
  UpCardSvg,
} from '@sundayapp/b2b-react-component-library';
import { CardBrand, PaymentMethodType } from '../domain/Payment';
import { ChequeVacancesIcon } from 'src/accounting/common/Icons/ChequeVacancesIcon';
import { CardBrandIcon } from './CardBrandIcon';

interface Props {
  methodType: PaymentMethodType,
  brand?: string | CardBrand,
}

export const ProviderIcon = ({ methodType, brand }: Props) => {
  switch (methodType) {
    case 'edenred':
      return (
        <IconWrapper size="medium">
          <EdenredCardSvg />
        </IconWrapper>
      );
    case 'sodexo':
      return (
        <IconWrapper size="medium">
          <SodexoCardSvg />
        </IconWrapper>
      );
    case 'pluxee':
      return (
        <IconWrapper size="medium">
          <SodexoCardSvg />
        </IconWrapper>
      );
    case 'ticket serviços':
      return (
        <IconWrapper size="medium">
          <TicketRestaurantCardSvg />
        </IconWrapper>
      );
    case 'up':
      return (
        <IconWrapper size="medium">
          <UpCardSvg />
        </IconWrapper>
      );
    case 'swile':
      return (
        <IconWrapper size="medium">
          <SwileCardSvg />
        </IconWrapper>
      );
    case 'bimpli':
      return (
        <IconWrapper size="medium">
          <BimpliCardSvg />
        </IconWrapper>
      );
    case 'cheque_vacances':
      return (
        <IconWrapper size="medium">
          <ChequeVacancesIcon />
        </IconWrapper>
      );
    case 'bancontact':
      return (
          <IconWrapper size="medium">
            <BanContactCardSvg />
          </IconWrapper>
      );
    case 'ideal':
      return (
          <IconWrapper g="medium">
            <IdealCardSvg />
          </IconWrapper>
      );
    case 'card':
      return <CardBrandIcon brand={brand} />;
    default:
      return <></>;
  }
};
