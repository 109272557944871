import { z } from 'zod';

export const coercedToNumberOrNull = z.coerce.number().nullish().optional().default(null);
export const StaffDtoSchema = z
  .object({
    waiterName: z
      .string()
      .nullish()
      .transform((v) => (v === null ? '' : v))
      .optional()
      .default(''),
    ratingOutOf5: coercedToNumberOrNull,
    nbOfReviews: coercedToNumberOrNull,
    totalRevenue: coercedToNumberOrNull,
    basketSize: coercedToNumberOrNull,
    nbSundayPayments: coercedToNumberOrNull,
    sundayPaymentsRate: coercedToNumberOrNull,
    totalTips: coercedToNumberOrNull,
    tipsRate: coercedToNumberOrNull,
    n5StarsGoogleReview: coercedToNumberOrNull,
    totalTipsPaymentTerminal: coercedToNumberOrNull,
    walkoutsSalesAmount: coercedToNumberOrNull,
    avgServiceRating: coercedToNumberOrNull,
    walkoutsTipsAmount: coercedToNumberOrNull,
    nbOfWalkouts: coercedToNumberOrNull,
    billViewRate: coercedToNumberOrNull,
  })
  .passthrough();

export type StaffDto = z.infer<typeof StaffDtoSchema>;
