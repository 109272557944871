import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Button, Card, CardContent, CardHeader, CircularProgress } from '@mui/material';
import { Menu } from '../../../domain/Menu';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useForm } from 'react-hook-form';
import { useUpdateMenuMutation } from 'src/menu/dynamic-menu/mutations/menu/useMenuMutation';
import { TextFieldWithLabel } from './TextFieldWithLabel';
import { MenuNameSettings } from 'src/menu/dynamic-menu/pages/MenuDetailsPage/components/MenuNameSettings';

type MenuNamePanelPros = {
  menu: Menu;
};

export type MenuFormData = {
  name: string;
  displayName: string;
  description: string;
  shortDescription: string;
};


function filterKeys<T extends object>(object: T, keys: Partial<Record<keyof T, boolean>>): Partial<T> {
  return Object.keys(keys).reduce((acc, key) => {
    const typedKey = key as keyof T;
    if (keys[typedKey]) {
      acc[typedKey] = object[typedKey];
    }
    return acc;
  }, {} as Partial<T>);
}


function MenuDetailsPanel({ menu }: MenuNamePanelPros) {
  const business = useCurrentBusinessOrThrow();

  const updateMenu = useUpdateMenuMutation(business.id, menu.id);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty, dirtyFields, errors },
  } = useForm<MenuFormData>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      name: menu.name,
      displayName: menu.displayName ?? '',
      description: menu.description,
      shortDescription: menu.shortDescription,
    },
  });

  async function onSubmit(inputs: MenuFormData) {
    // Get only dirty fields
    const dirtyData = filterKeys(inputs, dirtyFields);
    updateMenu.mutate({ ...dirtyData });
    reset(inputs, { keepDirty: false });
  }

  if (!business.patEnrollment && !menu.sundayMenu) {
    return <></>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ gap: 0 }}>
        <CardHeader title={<FormattedMessage id="menus.settings.details.title" defaultMessage="menu details" />}
                    action={
                      <Button type="submit" variant="contained" disabled={!isValid || isSubmitting || (!isDirty)}>
                        <FormattedMessage id={'common.save'} />
                        {isSubmitting && <CircularProgress sx={{ position: 'absolute', right: '0px' }} size='24px' /> }
                      </Button>
                    }
        />
        <CardContent sx={{ alignItems: 'start' }}>
          <MenuNameSettings menu={menu} register={register} errors={errors} />

          <TextFieldWithLabel registerProps={register('shortDescription')}
                              labelKey={'menus.settings.shortDesc.label'}
                              placeholderKey={'menus.settings.shortDesc.placeholder'}
                              helperText={errors.shortDescription?.message}
          />

          <TextFieldWithLabel registerProps={register('description')}
                              labelKey={'menus.settings.description.label'}
                              placeholderKey={'menus.settings.description.placeholder'}
                              helperText={errors.description?.message}
          />
        </CardContent>
      </Card>
    </form>
  );
}

export default MenuDetailsPanel;
