import { useCurrentBusinessOrThrow } from './useCurrentBusinessOrThrow';
import { Enrollment, EnrollmentStatus } from '../domain/Enrollment';
import { isMainProduct, mainProducts } from 'src/domain/venue/ServiceType';
import _ from 'lodash';

// TODO: temporary hook during migration, users MUST move away from this and handle business/enrollments correctly!
export const useFirstEnrollmentOfCurrentBusinessOrThrow = (): Enrollment | undefined => {
  const business = useCurrentBusinessOrThrow();
  const firstMainEnrollment: Enrollment | undefined = _.chain(business.enrollments)
    .filter((e) => isMainProduct(e.product))
    .filter((e) => e.status === EnrollmentStatus.ACTIVE)
    .orderBy((e) => mainProducts.indexOf(e.product))
    .head()
    .value();

  return firstMainEnrollment;
};
