import {
  AmericanExpressCardSvg,
  BimpliCardSvg,
  EdenredCardSvg,
  IconWrapper,
  MasterCardCardSvg,
  SodexoCardSvg,
  SwileCardSvg,
  UpCardSvg,
  VisaCardSvg,
} from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { DiscoverIcon } from './DiscoverIcon';
import { CardBrand } from '../domain/Payment';
import { JcbIcon } from 'src/accounting/common/Icons/JcbIcon';
import { UnionPayIcon } from 'src/accounting/common/Icons/UnionPayIcon';
import { ChequeVacancesIcon } from 'src/accounting/common/Icons/ChequeVacancesIcon';
import { CarteBancairesIcon } from 'src/accounting/common/Icons/CarteBancairesIcon';

interface Props {
  brand?: string | CardBrand
}

export const CardBrandIcon = ({
  brand,
}: Props) => {
  switch (brand) {
    case 'visa':
      return (
        <IconWrapper size="medium">
          <VisaCardSvg />
        </IconWrapper>
      );
    case 'mastercard':
      return (
        <IconWrapper size="medium">
          <MasterCardCardSvg />
        </IconWrapper>
      );
    case 'amex':
      return (
        <IconWrapper size="medium">
          <AmericanExpressCardSvg />
        </IconWrapper>
      );
    case 'swile':
      return (
        <IconWrapper size="medium">
          <SwileCardSvg />
        </IconWrapper>
      );
    case 'discover':
      return (
        <IconWrapper size="medium">
          <DiscoverIcon />
        </IconWrapper>
      );
    case 'bimpli':
      return (
        <IconWrapper size="medium">
          <BimpliCardSvg />
        </IconWrapper>
      );
    case 'up':
      return (
        <IconWrapper size="medium">
          <UpCardSvg />
        </IconWrapper>
      );
    case 'pass_restaurant':
      return (
        <IconWrapper size="medium">
          <SodexoCardSvg />
        </IconWrapper>
      );
    case 'jcb':
      return (
        <IconWrapper size="medium">
          <JcbIcon />
        </IconWrapper>
      );
    case 'unionpay':
      return (
        <IconWrapper size="medium">
          <UnionPayIcon />
        </IconWrapper>
      );
    // Fallback from payment.metadata.scheme. It's a very unlikely case (missing BIN), normally payment should fallback cardBrand to Visa or Mastercard.
    case 'CB':
      return (
        <IconWrapper size="medium">
          <CarteBancairesIcon />
        </IconWrapper>
      );
    // Fallback from payment.metadata.scheme
    case 'EDENRED':
      return (
          <IconWrapper size="medium">
            <EdenredCardSvg />
          </IconWrapper>
      );
    // Deprecated fallback from payment.metadata.scheme. Now it's a paymentMethodType.  
    case 'CHEQUE_VACANCES':
      return (
        <IconWrapper size="medium">
          <ChequeVacancesIcon />
        </IconWrapper>
      );
    default:
      return <></>;
  }
};
