import { Box, Card, Chip, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { FC } from 'react';
import { palette } from 'src/organization-report/design/palette';
import { MoneyView, useRenderMoney } from '@sundayapp/web-money';
import { useParams } from 'react-router';
import { useStaffTippingSummary } from 'src/tips/queries/useStaffTippingSummary';
import Spinner from 'src/ordering/common/components/Spinner';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { useGetAllStaff } from 'src/tips/dispatch/infrastructure/useGetAllStaff';
import { StaffTippingSummary, Transfer } from 'src/tips/domain/StaffTippingSummary';
import { Staff } from 'src/tips/dispatch/model/Staff';
import { useStaffPayoutMethodDetails } from 'src/tips/queries/useStaffPayoutMethodDetails';
import { PayoutMethodDetails } from 'src/tips/domain/PayoutMethodDetails';

const StaffInformation: FC<{
  user: Staff | undefined;
  payoutMethodDetails: PayoutMethodDetails | undefined;
}> = ({ user, payoutMethodDetails }) => {
  if (!user) {
    return <></>;
  }

  const buildPayoutMethodDetailsElement = () => {
    if (!payoutMethodDetails) {
      return <></>;
    } else {
      switch (payoutMethodDetails.payoutMethod) {
        case 'BankAccount':
          return (
            <>
              <Box display={'flex'} flexDirection={'row'} gap={2}>
                <Typography>Payout method provider:</Typography>
                <Typography fontWeight={'bold'}>{payoutMethodDetails.payoutMethod} (Revolut)</Typography>
              </Box>
              <Box display={'flex'} flexDirection={'row'} gap={2}>
                <Typography>Country code of residence:</Typography>
                <Typography fontWeight={'bold'}>{payoutMethodDetails.countryOfResidence}</Typography>
              </Box>
              <Box display={'flex'} flexDirection={'row'} gap={2}>
                <Typography>Birthdate:</Typography>
                <Typography fontWeight={'bold'}>{payoutMethodDetails.birthdate?.toLocaleDateString()}</Typography>
              </Box>
            </>
          );
        case 'None':
          return (
            <Box display={'flex'} flexDirection={'row'} gap={2}>
              <Typography>Payout method provider:</Typography>
              <Typography fontWeight={'bold'}>{payoutMethodDetails.payoutMethod}</Typography>
            </Box>
          );
      }
    }
  };

  return (
    <Stack>
      <Box display={'flex'} flexDirection={'row'} gap={2}>
        <Typography>Name:</Typography>
        <Typography fontWeight={'bold'}>
          {user.lastName} {user.firstName}
        </Typography>
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={2}>
        <Typography>Email:</Typography>
        <Typography fontWeight={'bold'}>{user.email}</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={2}>
        <Typography>Phone number:</Typography>
        <Typography fontWeight={'bold'}>{user.phoneNumber}</Typography>
      </Box>
      {buildPayoutMethodDetailsElement()}
    </Stack>
  );
};

const StaffTransfers: FC<{ tippingSummary: StaffTippingSummary }> = ({ tippingSummary }) => {
  const displayableTransfers = tippingSummary.transfers.filter(({ status }) => status !== 'CANCELED');

  const transferChipColor = (status: Transfer['status']) => {
    switch (status) {
      case 'SUSPENDED':
        return 'error';
      case 'SUCCEEDED':
        return 'success';
      case 'PENDING':
        return 'info';
    }
  };

  return (
    <>
      {displayableTransfers.length === 0 && (
        <Card sx={{ display: 'flex', padding: 3, gap: 1 }}>
          <Typography variant="body1">No transfers to display</Typography>
        </Card>
      )}
      {displayableTransfers.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography variant="subtitle2" color={palette.neutral.grey500}>
                  Amount
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2" color={palette.neutral.grey500}>
                  Status
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle2" color={palette.neutral.grey500}>
                  Date
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayableTransfers.map((transfer) => {
              return (
                <TableRow key={transfer.id}>
                  <TableCell align="center">
                    <MoneyView value={transfer.amount}></MoneyView>
                  </TableCell>
                  <TableCell align="center">
                    <Chip label={transfer.status} color={transferChipColor(transfer.status)}></Chip>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="subtitle2">
                      {transfer.createdAt.toLocaleDateString(undefined, {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export const StaffTippingSummaryPage = () => {
  const businessId = useBusinessIdOrThrow();
  const renderMoney = useRenderMoney(true, false);
  const params = useParams<{ businessId: string; id: string }>();
  const { isLoading: tippingSummaryIsLoading, data: tippingSummary } = useStaffTippingSummary(params.id!, 20);
  const { isLoading: staffPayoutMethodDetailsIsLoading, data: staffPayoutMethodDetails } = useStaffPayoutMethodDetails(
    params.id!,
  );

  const { data: businessStaffs, isLoading: isBusinessStaffsLoading } = useGetAllStaff(businessId);

  if (tippingSummary?.type === 'FAILURE') {
    return <Typography>Oops, something went wrong</Typography>;
  }

  if (
    !tippingSummary ||
    tippingSummaryIsLoading ||
    isBusinessStaffsLoading ||
    !businessStaffs ||
    staffPayoutMethodDetailsIsLoading
  ) {
    return <Spinner />;
  }

  const user = businessStaffs.find(({ externalId }) => externalId === params.id);
  return (
    <Box display="flex" gap="24px" flexDirection="column">
      <Card sx={{ padding: 2, width: '33%' }}>
        <Stack>
          <Box display={'flex'} flexDirection={'row'} gap={2} alignItems="center">
            <Typography>Tips balance:</Typography>
            <Typography fontWeight={'bold'}>{renderMoney(tippingSummary.data.balance)}</Typography>
          </Box>
          <StaffInformation user={user} payoutMethodDetails={staffPayoutMethodDetails} />
        </Stack>
      </Card>
      <StaffTransfers tippingSummary={tippingSummary.data} />
    </Box>
  );
};
