import React from 'react';
import { GridColumnGroupingModel } from '@mui/x-data-grid/models/gridColumnGrouping';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const HeaderGroupCell = styled.span`
  color: #70707b;
`;

export const buildGroupColumns = (): GridColumnGroupingModel => [
  {
    groupId: 'Waiter',
    description: '',
    children: [{ field: 'waiterName' }],
    renderHeaderGroup: () => (
      <HeaderGroupCell>
        <FormattedMessage id="staff_performance.group.waiter" />
      </HeaderGroupCell>
    ),
  },
  {
    groupId: 'Reviews',
    description: '',
    children: [
      { field: 'ratingOutOf5' },
      { field: 'nbOfReviews' },
      { field: 'n5StarsGoogleReview' },
      { field: 'avgServiceRating' },
    ],
    renderHeaderGroup: () => (
      <HeaderGroupCell>
        <FormattedMessage id="staff_performance.group.reviews" />
      </HeaderGroupCell>
    ),
  },
  {
    groupId: 'Revenue',
    description: '',
    children: [{ field: 'totalRevenue' }, { field: 'basketSize' }],
    renderHeaderGroup: () => (
      <HeaderGroupCell>
        <FormattedMessage id="staff_performance.group.revenues" />
      </HeaderGroupCell>
    ),
  },
  {
    groupId: 'Sunday_Payments',
    description: '',
    children: [{ field: 'nbSundayPayments' }, { field: 'sundayPaymentsRate' }],
    renderHeaderGroup: () => (
      <HeaderGroupCell>
        <FormattedMessage id="staff_performance.group.sunday_payments" />
      </HeaderGroupCell>
    ),
  },
  {
    groupId: 'walkouts',
    description: '',
    children: [{ field: 'nbOfWalkouts' }, { field: 'walkoutsSalesAmount' }],
    renderHeaderGroup: () => (
      <HeaderGroupCell>
        <FormattedMessage id="staff_performance.walkouts" />
      </HeaderGroupCell>
    ),
  },
  {
    groupId: 'Tip',
    description: '',
    children: [{ field: 'totalTips' }, { field: 'tipsRate' }],
    renderHeaderGroup: () => (
      <HeaderGroupCell>
        <FormattedMessage id="staff_performance.group.sunday_tips" />
      </HeaderGroupCell>
    ),
  },
  {
    groupId: 'Adoption',
    description: '',
    children: [{ field: 'billViewRate' }],
    renderHeaderGroup: () => (
      <HeaderGroupCell>
        <FormattedMessage id="staff_performance.group.bill_viewed" />
      </HeaderGroupCell>
    ),
  },
];
