// eslint-disable-next-line @typescript-eslint/no-redeclare
import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

export type MenuItemNameProps = {
  name: string,
  active: boolean,
  displayName: string | undefined
};

const MenuItemName = ({ name, active, displayName }: MenuItemNameProps) => {
  const hasDistinctDisplayName = !!displayName && displayName !== name;

  return (
    <Tooltip title={hasDistinctDisplayName ? name : undefined} arrow>
    <Box flexDirection="row" display="flex" textOverflow="ellipsis">
      <Typography variant={'h6'} sx={{ textOverflow: 'ellipsis' }} color={active ? 'textPrimary' : 'textDisabled'}>
        { hasDistinctDisplayName ? `${displayName}` : name}
      </Typography>
    </Box>
    </Tooltip>
  );
};

export default MenuItemName;
