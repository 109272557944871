import {
  Box, LinearProgress, styled, Typography,
} from '@mui/material';
import React from 'react';
import { SundayPaymentsRate } from './StaffPerformanceViewModel';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 0,
}));

export const SundayPaymentsRateColumn = ({ sundayPaymentsRate }: { sundayPaymentsRate: SundayPaymentsRate }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', minWidth: 80 }}>
    {sundayPaymentsRate?.rate
      && (
        <Box sx={{ display: 'flex', minWidth: 50 }}>
          <BorderLinearProgress
            sx={{ marginRight: '8px', width: '80px' }}
            variant="determinate"
            value={sundayPaymentsRate.rate}
          />
        </Box>
      )}
    <Box sx={{ display: 'flex',  minWidth: 30 }}>
      <Typography
        align="center"
        variant="body2"
      >
        {sundayPaymentsRate?.label ?? ''}
      </Typography>
    </Box>
  </Box>
);
