/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/no-redeclare
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Slider } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Box } from '@mui/material';
import { getSpacing } from '../../../stylesheet';

type PictureManipulatorProps = {
  image?: HTMLImageElement;
  zoom: number;
  setZoom: (value: number) => void;
  width: number;
  height: number;
  exportWidth: number;
  exportHeight: number;
  onUploadNewFile: () => void;
  isEditMode: boolean;
  onImageContentChanged: (content: string) => void;
  onCancel: () => void;
  disabled: boolean;
  imageUrl?: string | null;
};

interface PictureSizeProps {
  pictureWidth: number;
  pictureHeight: number;
}

const SaveLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${getSpacing(2)};
`;

const TransparentStyleBackground = styled.div<PictureSizeProps>`
  display: flex;
  position: relative;
  max-width: ${(props) => props.pictureWidth}px;
  max-height: ${(props) => props.pictureHeight}px;
  width: ${(props) => props.pictureWidth}px;
  height: ${(props) => props.pictureHeight}px;
  border-radius: 16px;
  background-repeat: repeat;
  background-size: 20px 20px;
  background-position: 0 0,
  0 10px,
  10px -10px,
  -10px 0;
  background-image: linear-gradient(45deg, rgb(192, 192, 192) 25%, transparent 25%),
  linear-gradient(-45deg, rgb(192, 192, 192) 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, rgb(192, 192, 192) 75%),
  linear-gradient(-45deg, transparent 75%, rgb(192, 192, 192) 75%);
  margin-bottom: ${getSpacing(2)};
`;

const PictureCanvas = styled.canvas<PictureSizeProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.pictureWidth}px;
  height: ${(props) => props.pictureHeight}px;
  border-radius: 16px;
`;

const BackgroundGoToEditMode = styled.div<PictureSizeProps>`
  position: absolute;
  width: ${(props) => props.pictureWidth}px;
  height: ${(props) => props.pictureHeight}px;
  border-radius: 16px;
  background-color: #ffffff70;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type DragInfo = {
  startX: number;
  startY: number;
  currentX: number;
  currentY: number;
};

type Vector2d = {
  X: number;
  Y: number;
};

const NO_OFFSET: Vector2d = {
  X: 0,
  Y: 0,
};

const computeMinZoom = (pictureWidth: number, pictureHeight: number, image: HTMLImageElement): number => {
  const zoomXMin = pictureWidth / image.width;
  const zoomYMin = pictureHeight / image.height;
  return Math.max(zoomXMin, zoomYMin) / 2;
};

export const PictureManipulator = ({
  image,
  zoom,
  setZoom,
  width,
  height,
  exportWidth,
  exportHeight,
  onUploadNewFile,
  isEditMode,
  onImageContentChanged,
  onCancel,
  disabled,
  imageUrl,
}: PictureManipulatorProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [dragInfo, updateDragInfo] = useState<DragInfo>();
  const [offset, updateOffset] = useState<Vector2d>(NO_OFFSET);
  const [startPos, updateStartPos] = useState<Vector2d>(NO_OFFSET);
  const [currentImage, updateCurrentImage] = useState<HTMLImageElement>();
  const [minZoom, setMinZoom] = useState<number>(1);

  const drawImage = useCallback(() => {
    const canvas = canvasRef.current!;
    const context = canvas.getContext('2d')!;

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    const diffX = dragInfo ? dragInfo.currentX - dragInfo.startX : 0;
    const diffY = dragInfo ? dragInfo.currentY - dragInfo.startY : 0;

    const pX = startPos.X + (diffX + offset.X - zoom * context.canvas.width * 0.5);
    const pY = startPos.Y + (diffY + offset.Y - zoom * context.canvas.height * 0.5);
    context.drawImage(
      currentImage!,
      0,
      0,
      currentImage!.width,
      currentImage!.height,
      pX,
      pY,
      currentImage!.width * zoom,
      currentImage!.height * zoom,
    );
  }, [currentImage, dragInfo, offset, startPos, zoom]);

  const setStartPos = (
    startPictureWidth: number,
    startPictureHeight: number,
    startImage: HTMLImageElement,
    startZoom: number,
  ) => {
    // Manage the legacy picture size and center it
    if (startImage.width === 680 && startImage.height === 680) {
      updateStartPos({
        X: startPictureWidth * startZoom,
        Y: startPictureHeight * startZoom - 115,
      });
      return;
    }
    updateStartPos({
      X: startPictureWidth * startZoom,
      Y: startPictureHeight * startZoom,
    });
  };

  useEffect(() => {
    updateDragInfo(undefined);
    updateOffset(NO_OFFSET);
    updateCurrentImage(image);

    const canvas = canvasRef.current!;
    if (image && canvas) {
      canvas.width = exportWidth;
      canvas.height = exportHeight;

      const computedMinZoom = computeMinZoom(exportWidth, exportHeight, image);
      setMinZoom(computedMinZoom);
      setZoom(computedMinZoom * 2);

      setStartPos(exportWidth, exportHeight, image, computedMinZoom);
    }
  }, [image, height, width, setZoom]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    if (!currentImage) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      return;
    }

    drawImage();
  }, [currentImage, drawImage]);

  const onMouseDown = (mouseEvent: MouseEvent) => {
    if (!isEditMode) {
      return;
    }

    updateDragInfo({
      startX: mouseEvent.clientX,
      startY: mouseEvent.clientY,
      currentX: mouseEvent.clientX,
      currentY: mouseEvent.clientY,
    });
  };

  const onMouseMove = (mouseEvent: MouseEvent) => {
    if (!dragInfo || !isEditMode) {
      return;
    }

    updateDragInfo({ ...dragInfo, currentX: mouseEvent.clientX, currentY: mouseEvent.clientY });
  };

  const stopDrag = () => {
    if (!dragInfo || !isEditMode) {
      return;
    }

    updateOffset({
      X: offset.X + dragInfo.currentX - dragInfo.startX,
      Y: offset.Y + dragInfo.currentY - dragInfo.startY,
    });
    updateDragInfo(undefined);
  };

  const onClickOnCanvas = () => {
    if (disabled) return;
    if (isEditMode) {
      return;
    }

    onUploadNewFile();
  };

  const saveImage = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    onImageContentChanged(canvas.toDataURL());
  }, [onImageContentChanged]);

  const onCancelChanges = useCallback(() => {
    setMinZoom(1);
    updateOffset(NO_OFFSET);
    updateStartPos(NO_OFFSET);
    onCancel();
  }, [onCancel]);

  return (
    <Box onClick={onClickOnCanvas} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <TransparentStyleBackground pictureWidth={width} pictureHeight={height}>
        {isEditMode && (
          <PictureCanvas
            pictureWidth={width}
            pictureHeight={height}
            ref={canvasRef}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={stopDrag}
            onMouseLeave={stopDrag}
          />
        )}

        {!disabled && !isEditMode && (
          <>
            {imageUrl && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '16px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundImage: `url("${imageUrl}")`,
                }}
              />
            )}
            <BackgroundGoToEditMode pictureWidth={width} pictureHeight={height}>
              <FormattedMessage id="picture-picker.click-to-edit" defaultMessage="click the picture to edit" />
            </BackgroundGoToEditMode>
          </>
        )}
      </TransparentStyleBackground>
      {!disabled && isEditMode && (
        <>
          <Slider
            value={zoom}
            min={minZoom}
            max={minZoom * 10}
            step={0.01}
            handleChange={(value) => {
              setZoom(Array.isArray(value) ? value[0] : value);
            }}
          />
          <SaveLine>
            <Button variant="tertiary" size="small" onClick={onCancelChanges}>
              <FormattedMessage id="picture-picker.cancel" defaultMessage="Cancel" />
            </Button>

            <Button variant="primary" size="small" onClick={saveImage}>
              <FormattedMessage id="picture-picker.save" defaultMessage="Save" />
            </Button>
          </SaveLine>
        </>
      )}
    </Box>
  );
};
