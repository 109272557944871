import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Autocomplete, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Filters } from './usePayments';
import { paymentDisplayStatus } from '../domain/Payment';
import { Business } from 'src/business/domain/Business';
import { DatePresets, Period, TimeFrameFilter } from '../../components/time-frame-filter/TimeFrameFilter';
import { SearchRounded } from '@mui/icons-material';
import { PaymentsListDatePreset, paymentsListDatePresetsTypes } from '../components/PaymentsListDatePresets';

type Props = {
  changeFilter: (filter: Partial<Filters>) => void,
  filters: Filters,
  business: Business,
  hasTabPaymentsToDisplay: boolean,
  tpeVenue: boolean;
  datePresets: DatePresets<typeof paymentsListDatePresetsTypes>;
  period: Period<PaymentsListDatePreset>;
  waiters: string[];
};

const searchInputProps = {
  sx: { paddingRight: 0 },
  endAdornment: (
    <InputAdornment position="start">
      <SearchRounded />
    </InputAdornment>
  ),
};
const inputWidth = { width: '100%', maxWidth: '300px' };

export const PaymentsFilter = ({
  changeFilter,
  filters,
  business,
  hasTabPaymentsToDisplay,
  tpeVenue,
  datePresets,
  period,
  waiters,
}: Props) => {
  const intl = useIntl();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} md={4} xl={2} display="flex" alignItems="center" justifyContent="center">
        <TimeFrameFilter datePresets={datePresets} period={period} sx={inputWidth} />
      </Grid>
      <Grid item xs={3} md={2} xl={1} display="flex" alignItems="center" justifyContent="center">
        <FormControl size="small" sx={inputWidth}>
          <InputLabel id="filter-status"><FormattedMessage id="payments.table.status" /></InputLabel>
          <Select
            labelId="filter-status"
            id="filter-status"
            sx={{ width: '100%' }}
            label={intl.formatMessage({ id: 'payments.table.status' })}
            value={filters.status}
            onChange={(e: any) => {
              changeFilter({ status: e.target.value });
            }}
            autoWidth
          >
            <MenuItem value="all"><FormattedMessage id="payment.status.all" /></MenuItem>
            {paymentDisplayStatus.map((s) => (
              <MenuItem key={s} value={s}>
                <FormattedMessage id={`payments.status.${s}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {business.patEnrollment !== undefined && (
        <Grid item xs={3} md={3} xl={2} display="flex" alignItems="center" justifyContent="center">
          <FormControl sx={inputWidth} size="small">
            <TextField
              onChange={(e: any) => {
                changeFilter({ tableNumber: e.target.value });
              }}
              value={filters.tableNumber}
              size="small"
              InputProps={searchInputProps}
              label={intl.formatMessage({ id: hasTabPaymentsToDisplay ? 'orders.table.table_number_or_tab' : 'payments.filter.table_number' })}
            />
          </FormControl>
        </Grid>
      )}
      {business.oapEnrollment !== undefined && (
        <Grid item xs={4} md={4} xl={2} display="flex" alignItems="center" justifyContent="center">
          <FormControl sx={inputWidth} size="small">

            <TextField
              onChange={(e: any) => {
                changeFilter({ orderIdDisplay: e.target.value });
              }}
              value={filters.orderIdDisplay}
              size="small"
              InputProps={searchInputProps}
              label={intl.formatMessage({ id: 'payments.filter.orderId' })}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={3} md={2} xl={2} display="flex" alignItems="center" justifyContent="center">
        <FormControl sx={inputWidth} size="small">
          <TextField
            onChange={(e: any) => {
              changeFilter({ amount: e.target.value });
            }}
            value={filters.amount}
            size="small"
            InputProps={searchInputProps}
            label={intl.formatMessage({ id: 'payments.filter.amount' })}
          />
        </FormControl>
      </Grid>
      {(business.patEnrollment || business.pdqEnrollment) && (
        <Grid item xs={3} md={2} xl={2} display="flex" alignItems="center" justifyContent="center">
          <Autocomplete
            disablePortal
            id="waiter"
            options={waiters}
            sx={{ width: '100%' }}
            size="small"
            value={filters.waiter}
            onChange={(_, value) => {
              changeFilter({ waiter: value });
            }}
            renderInput={(params) => <TextField {...params}
                                                label={intl.formatMessage({ id: 'payments.waiter_name' })} />}
          />
        </Grid>)}
      <Grid item xs={3} md={2} xl={1} display="flex" alignItems="center" justifyContent="center">
        <FormControl sx={inputWidth} size="small">
          <TextField
            onChange={(e: any) => {
              changeFilter({ cardNumber: e.target.value });
            }}
            value={filters.cardNumber}
            size="small"
            InputProps={searchInputProps}
            label={intl.formatMessage({ id: 'reconciliation.report.payment_method.type.card' })}
          />
        </FormControl>
      </Grid>
      {tpeVenue && (
        <Grid item xs={3} md={3} xl={2} display="flex" alignItems="center" justifyContent="center">
          <FormControl sx={inputWidth} size="small">

            <TextField
              onChange={(e: any) => {
                changeFilter({ serialNumber: e.target.value });
              }}
              value={filters.serialNumber}
              size="small"
              InputProps={searchInputProps}
              label={intl.formatMessage({ id: 'payments.filter.serial_number' })}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
