import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';
import { useUpdateGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useUpdateGlobalConfiguration';
import { GlobalConfiguration } from 'src/venueconfiguration/domain/GlobalConfiguration';
import { ReviewConfiguration } from 'src/sundayplus/reviews/browse/domain/ReviewConfigurationFactory';
import {
  reviewConfigurationRepository,
} from 'src/sundayplus/reviews/browse/infrastructure/HttpReviewConfigurationRepository';
import { BusinessId } from 'src/business/domain/Business';

export const useReviewsEnabled = (): boolean => {
  const { data } = useGlobalConfiguration((globalConfiguration: GlobalConfiguration) =>
    globalConfiguration.entries.get(VenueConfigurationKey.reviewsEnabled),
  );
  return data ?? false;
};

export const useExternalSiteRatingThreshold = (): number => {
  const { data } = useGlobalConfiguration((globalConfiguration: GlobalConfiguration) =>
    globalConfiguration?.entries.get(VenueConfigurationKey.externalSiteRatingThreshold),
  );
  return data ?? 1;
};

export const useSaveReviewConfiguration = (businessId: BusinessId) => {
  const reviewsEnabled = useReviewsEnabled();
  const externalSiteRatingThreshold = useExternalSiteRatingThreshold();
  const { updateGlobalConfiguration } = useUpdateGlobalConfiguration();

  return async (newConfigurationFields: Partial<ReviewConfiguration>) => {
    const updatedConfig: ReviewConfiguration = {
      reviewsEnabled,
      externalSiteRatingThreshold,
      ...newConfigurationFields,
    };
    return Promise.allSettled([
      updateGlobalConfiguration(
        new Map<VenueConfigurationKey, any>([
          [VenueConfigurationKey.reviewsEnabled, String(updatedConfig.reviewsEnabled)],
          [VenueConfigurationKey.externalSiteRatingThreshold, updatedConfig.externalSiteRatingThreshold],
        ]),
      ),
      reviewConfigurationRepository.save(businessId, updatedConfig),
    ]);
  };
};
