import React, { useMemo } from 'react';
import { Button, InfoSvg, Panel, Table, TableCell, TableRow } from '@sundayapp/b2b-react-component-library';
import { Box, CircularProgress, styled, TableBody, TableHead, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Linkify from 'linkify-react';
import { MethodType } from '../domain/Reconciliation';
import { ReportHeader } from './ReportHeader';
import { OtherTenderRow } from './other/OtherTenderRow';
import { useReport } from '../queries/getReport';
import { CashupStatus, isOpened } from '../domain/ReconciliationReport';
import { venueReconciliationTabPath } from 'src/app/navigation/pathHelpers';
import { BackButton } from './BackButton';
import { useReportSettings } from '../queries/ReportSettings';
import { ReportHistoryPanel } from './ReportHistoryPanel';
import { ReportSavePanel } from './ReportSavePanel';
import { ReportCommentEdition } from './ReportCommentEdition';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { roleForBusiness } from 'src/domain/user/Role';
import { useReopenReportMutation } from '../mutations/useReopenReportMutation';
import { CashPaymentRow } from './cash/CashPaymentRow';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetReconciliationConfiguration } from '../queries/getReconciliationConfigurationQuery';
import { OrphanPosLineRow } from './other/OrphanPosLineRow';

const MainPanel = styled(Panel)`
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  align-items: stretch;
`;

const MainTable = styled(Table)`
  border-collapse: collapse;
  border-bottom: 1px solid lightgrey;

  & {
    tbody, thead {
      & > tr {
        border-bottom: 1px solid lightgrey;
        height: 40px;
      }
    }
  }
`;

export const ReconciliationReport = () => {
  const theme = useTheme();
  const business = useCurrentBusinessOrThrow();
  const user = useAuthenticatedUserOrThrow();
  const userRoleForSelectedBusiness = roleForBusiness(user.claims, business.id);
  const { data: reconciliationConfiguration } = useGetReconciliationConfiguration(business.id);
  const isOrphanPosLinesDisplayed = reconciliationConfiguration?.orphanPosLinesDisplayed ?? false;
  const { reportId } = useParams();
  const { data: reportSettings } = useReportSettings();
  const navigate = useNavigate();
  const { data: report, isLoading } = useReport(reportId!);
  const reopenReportMutation = useReopenReportMutation(business.id);
  const canReopen = useMemo(() => report?.status === CashupStatus.Closed && userRoleForSelectedBusiness === 'ADMIN', [user, business, report]);

  if (isLoading || !report || !reportSettings) {
    return <CircularProgress />;
  }

  const reopen = () => {
    reopenReportMutation.mutate(reportId!);
  };

  const hasOrphans = report.orphanPosLines && report.orphanPosLines.length > 0;

  const backToReportList = () => {
    const tabName = report.status === CashupStatus.Open ? 'open' : 'history';
    navigate(venueReconciliationTabPath(business.id, tabName));
  };

  return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    {reportSettings.documentationActivated && reportSettings.documentation
      && <Panel
        flexDirection="row"
        style={{
          display: 'flex',
          gap: '5px',
          marginBottom: '15px',
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1',
        }}
      >
        <InfoSvg />
        <Typography variant="body2" color="#01579B">
          <Linkify options={{ target: '_blank' }}>
            {reportSettings.documentation}
          </Linkify>
        </Typography>
      </Panel>}

    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
    }}
    >
      <BackButton onClick={backToReportList} />
      {canReopen && <Button variant="primary" size="small" onClick={reopen}>
        <FormattedMessage id="reconciliation.report.reopen" />
      </Button>}
    </Box>
    <MainPanel>
      <MainTable size="small" sx={{ minWidth: '100%' }}>
        <TableHead>
          <ReportHeader />
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            {(['payment_method', 'on_pos', 'reported', 'delta'] as const).map((columnId) => <TableCell key={columnId}>
              <Typography variant="body2" color={theme.palette.text.secondary}>
                <FormattedMessage id={`reconciliation.report_table.${columnId}`} />
              </Typography>
            </TableCell>)}
          </TableRow>
          {
            report.reconciliations.map(
              (tender) => {
                switch (tender.method.type) {
                  case MethodType.Cash:
                    return <CashPaymentRow tender={tender} key={tender.method.name} reportSettings={reportSettings} />;
                  default:
                    return <OtherTenderRow tender={tender} key={tender.method.name} reportSettings={reportSettings} />;
                }
              },
            )
          }
          {isOrphanPosLinesDisplayed && hasOrphans && <TableRow style={{ borderTop: 'solid' }}>
            <TableCell />
            <TableCell>
              <Typography variant="h6">
                <FormattedMessage id="reconciliation.report_table.orphan_pos_lines_title" defaultMessage="POS methods not configured" />
              </Typography>
            </TableCell>
          </TableRow>}
          {isOrphanPosLinesDisplayed && hasOrphans && report.orphanPosLines?.map(
            (line) => {
              return (
                <OrphanPosLineRow line={line} businessId={business.id} key={line.posPaymentMethodId.id} />
              );
            },
          )
          }
        </TableBody>
      </MainTable>
      <ReportCommentEdition />
    </MainPanel>
    <ReportHistoryPanel />
    {isOpened(report) && <ReportSavePanel />}
  </Box>;
};
