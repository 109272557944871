import React, { useState } from 'react';
import { Alert, Box, Drawer, Typography, useTheme } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useNavigate, useOutletContext } from 'react-router';
import { venuePath } from 'src/app/navigation/pathHelpers';
import { RevenuePeriod } from '../domain/RevenuesDatePresets';
import { Downloads } from './components/sunday-payout-summary/Downloads';
import { AccountingDates } from './components/sunday-payout-summary/AccountingDates';
import { SundayPayoutSummaryBreakdown } from './components/sunday-payout-summary/SundayPayoutSummaryBreakdown';
import { BusinessId } from 'src/business/domain/Business';
import { RevenuesSummary } from '../domain/RevenueSummary';
import { sundayPayoutSummary } from '../domain/SundayPayoutSummary';
import { revenueSources, revenueSourceTypes } from '../domain/RevenueSource';
import { CurrencyCode } from '@sundayapp/web-money';
import { URL_DATE_FORMAT } from 'src/components/time-frame-filter/TimeFrameFilter';

// TODO MID : no fixed width for drawer
const DRAWER_WIDTH = 592;

// TODO MID : CloseButton and CloseIconButton are the same as in Estimation component, extract ?
const CloseButton = styled.div`
  cursor: pointer;
  margin-top: 32px;
  margin-left: 32px;
`;

const CloseIconButton = styled(CloseIcon)``;

export const SundayPayoutSummaryDrawer = () => {
  const {
    businessId,
    timezone,
    period,
    revenueSummary,
    businessCurrency,
  } = useOutletContext<{
    businessId: BusinessId,
    businessCurrency: CurrencyCode,
    timezone: string,
    period: RevenuePeriod,
    revenueSummary: RevenuesSummary
  }>();
  const navigate = useNavigate();
  const theme = useTheme();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  const payoutSummary = sundayPayoutSummary(revenueSummary, businessCurrency, theme);

  const hasPaymentTerminalRevenues = revenueSummary.revenues
    .some((revenue) => revenue.source === revenueSources.PAYMENT_TERMINAL && revenue.totalSalesAndTips.amount > 0);
  const hasQrCodesMealVoucherRevenues = revenueSummary.revenues
    .filter((revenue) => revenue.source === revenueSources.QR_CODE)
    .flatMap((revenue) => revenue.revenuesDetails)
    .some((detail) => detail.source === revenueSourceTypes.MEAL_VOUCHERS && detail.revenueAmounts.totalSalesAndTips.amount > 0);

  function navigateBack() {
    const startDate = period.dateRange.startDate.format(URL_DATE_FORMAT);
    const endDate = period.dateRange.endDate.format(URL_DATE_FORMAT);
    navigate(
      `${venuePath(businessId)}/accounting/revenues?preset=${period.filter}&startDate=${startDate}&endDate=${endDate}`,
    );
  }

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    navigateBack();
  };

  const drawerContent = () => (
    <Box display="flex" justifyContent="space-between" flexDirection="column" height="100%">
      <Box>
        <CloseButton onClick={() => closeDrawer()}>
          <CloseIconButton data-intercom-target={'accounting.revenues.sunday_payout_summary.title'} />
        </CloseButton>
        <Typography variant="body1" sx={{ letterSpacing: '-0.5px' }} ml={4} mt={2} color={theme.palette.text.secondary}>
          <FormattedMessage id="accounting.revenues.sunday_payout_summary.subtitle" />
        </Typography>
        <Typography variant="h3" sx={{ letterSpacing: '-3px' }} ml={4} mb={1} color={theme.palette.text.primary}>
          <FormattedMessage id="accounting.revenues.sunday_payout_summary.title" />
        </Typography>

        <AccountingDates period={period} timezone={timezone} />
        <SundayPayoutSummaryBreakdown sundayPayoutSummary={payoutSummary} />

        <Box p={2} gap={'8px'} display="flex" flexDirection="column">
          {hasPaymentTerminalRevenues && <Alert severity="info" sx={{ minWidth: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>
                <FormattedMessage id="accounting.revenues.sunday_payout_summary.disclaimers.payment_terminal" />
              </Typography>
            </Box>
          </Alert>}

          {hasQrCodesMealVoucherRevenues && <Alert severity="info" sx={{ minWidth: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>
                <FormattedMessage id="accounting.revenues.sunday_payout_summary.disclaimers.qr_codes_meal_voucher" />
              </Typography>
            </Box>
          </Alert>}
        </Box>
      </Box>

      <Downloads period={period} />
    </Box>
  );

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        {isDrawerOpen
          && (
            <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}
                    PaperProps={{ sx: { width: DRAWER_WIDTH } }}>{drawerContent()}</Drawer>
          )}
      </Box>
    </>
  );
};
