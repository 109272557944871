import BlockIcon from '@mui/icons-material/Block';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { TableCell, Tooltip, Typography } from '@mui/material';
import MUIIconButton from '@mui/material/IconButton';
import { Badge } from '@sundayapp/b2b-react-component-library';
import { Money, MoneyView } from '@sundayapp/web-money';
import { Link } from 'react-router';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { LocalisationKey } from 'src/lang/en';
import { Bill, BillStatus, billStatus } from '../domain/Bill';
import { BillToDisplay } from './BillToDisplay';
import { BillFreshness } from './BillsTable';
import { RefreshBillButtonView } from './RefreshButton';
import { BusinessDate } from 'src/components/date/BusinessDate';

const tooltip = (freshness: BillFreshness, isAlreadyCaptured: boolean): string => {
  if (isAlreadyCaptured) {
    return 'already captured';
  } else if (freshness.tooOld) {
    return 'too old to be captured';
  } else if (freshness.stalled) {
    return 'refresh before capture';
  }
  return 'capture left to pay';
};

const CaptureButton = ({
  bill,
  onCaptureClick,
  freshness,
}: {
  bill: Bill;
  onCaptureClick: () => void;
  freshness: BillFreshness;
}) => {
  if (bill.tab !== undefined && bill.leftToPay.amount > 0) {
    const isAlreadyCaptured = !!bill.tab.chargeLeftToPayAmountRequestedAt;
    const color = freshness.tooOld || freshness.stalled || isAlreadyCaptured ? 'disabled' : 'inherit';
    const tooltipLabel = tooltip(freshness, isAlreadyCaptured);

    return (
      <MUIIconButton onClick={isAlreadyCaptured ? () => {
      } : onCaptureClick}>
        <Tooltip title={tooltipLabel}>
          <CurrencyExchangeIcon color={color} width="1em" />
        </Tooltip>
      </MUIIconButton>
    );
  }
  return null;
};

const computeStatusToBadgeVariant = (status: BillStatus): string => {
  switch (status) {
    case BillStatus.NOT_PAID:
    case BillStatus.PARTIALLY_PAID:
      return 'yellow';
    case BillStatus.SETTLED:
      return 'green';
    case BillStatus.OVERPAID:
      return 'red';
    case BillStatus.EMPTY:
      return 'grey';
  }
};
const computeStatusToLabelKey = (bill: BillToDisplay): LocalisationKey => {
  if (bill.tab && bill.tab.chargeLeftToPayAmountRequestedAt !== undefined) {
    return 'orders.status.paid';
  }
  switch (billStatus(bill)) {
    case BillStatus.NOT_PAID:
    case BillStatus.PARTIALLY_PAID:
      return 'orders.status.to_be_paid';
    case BillStatus.SETTLED:
      return 'orders.status.paid';
    case BillStatus.OVERPAID:
      return 'orders.status.overpaid';
    case BillStatus.EMPTY:
      return 'orders.status.empty';
  }
};
const asAbsolute = ({
  amount,
  currency,
}: Money): Money => ({
  amount: Math.abs(amount),
  currency,
});

const couldBeCaptured = (bill: BillToDisplay): boolean => bill.tab !== undefined && bill.leftToPay.amount > 0;

interface Props {
  bill: BillToDisplay;
  hasCheckNumbers: boolean;
  onCaptureClick: () => void;
  refreshBill: (billId: string, isTab: boolean) => Promise<void>;
  freshness: BillFreshness;
}

const IdCell = ({ bill }: { bill: BillToDisplay }) => {
  if (bill.tab) {
    return (
      <TableCell align="left">
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Typography>{bill.tab.userName ?? bill.tab.displayId}</Typography>
          <Typography color={themeV5.palette.text.secondary}>{bill.tab.userName ? bill.tab.displayId : ''}</Typography>
          <Typography color={themeV5.palette.text.secondary}>
            {bill.tableNumber !== 'N/A' ? bill.tableNumber : ''}
          </Typography>
        </div>
      </TableCell>
    );
  }
  return <TableCell align="left">{bill.tableNumber}</TableCell>;
};

export function BillTableRow({
  bill,
  hasCheckNumbers,
  onCaptureClick,
  refreshBill,
  freshness,
}: Props) {
  const intl = useIntl();
  const billSettlementLabelKey = computeStatusToLabelKey(bill);

  return (
    <>
      <IdCell bill={bill} />
      {hasCheckNumbers && <TableCell align="left">{bill.order.checkNumber}</TableCell>}
      <TableCell align="left">
        {bill.issuedAt && (
          <BusinessDate instant={bill.issuedAt} />
        )}
      </TableCell>
      <TableCell align="left">{bill.order.staffName}</TableCell>
      <TableCell align="right">
        <Badge size="large" variant={computeStatusToBadgeVariant(billStatus(bill))} style={{ whiteSpace: 'pre' }}>
          {[BillStatus.PARTIALLY_PAID, BillStatus.NOT_PAID, BillStatus.OVERPAID].includes(billStatus(bill)) && (
            <span>
              <MoneyView value={asAbsolute(bill.leftToPay)} />{' '}
            </span>
          )}
          <FormattedMessage id={billSettlementLabelKey} />
        </Badge>
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: 'pre' }}>
        <MoneyView value={bill.totalBillAmount} />
      </TableCell>
      <TableCell align="center">
        <Link to={bill.ticketLink} target="_blank" rel="noreferrer">
          <MUIIconButton>
            <ListAltIcon width="1em" />
          </MUIIconButton>
        </Link>
      </TableCell>
      <TableCell align="center">
        <CaptureButton bill={bill} onCaptureClick={onCaptureClick} freshness={freshness} />
        {bill.paymentLink && (
          <Link to={bill.paymentLink}>
            <MUIIconButton>
              <CreditCardIcon width="1em" />
            </MUIIconButton>
          </Link>
        )}
        {!couldBeCaptured && !bill.paymentLink && (
          <Tooltip title={intl.formatMessage({ id: 'orders.table.payments.no_sunday' })}>
            <BlockIcon width="1em" />
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        <RefreshBillButtonView
          orderId={bill.order.orderId}
          refreshBill={refreshBill}
          freshness={freshness}
          isTab={bill.tab !== undefined}
          billPaymentStatus={billStatus(bill)}
        />
      </TableCell>
    </>
  );
}
