import { CurrencyCode } from '@sundayapp/web-money';
import { Author, EMPTY, Platform, Review } from '../domain/Review';
import { Instant } from '../../../Instant';

import { parseDimension } from '../domain/Dimension';

export type ReviewResponse = {
  id: string;
  businessId?: string;
  creationDate: string;
  platform: 'GOOGLE' | 'SUNDAY';
  rating: number;
  comment?: { content: string }
  reply?: { content: string };
  author?: Author;
  reviewContext?: {
    table?: { name: string };
    area?: { name: string };
    waiter?: { name: string };
    billAmount?: { amount: number, currency: CurrencyCode };
    paidAmount?: { amount: number, currency: CurrencyCode };
    order?: { id: string };
  }
  replyIsPossible: boolean;
  comingFromSunday: boolean;
  dimensions: DimensionRatingResponse[]
};

export type DimensionRatingResponse = { rating: number; dimension: string };

const getOrEmpty = (value: string | undefined) => {
  if (value === undefined) return EMPTY;
  return value;
};

function mapDimension(reviewRatingDimensionResponse: DimensionRatingResponse) {
  return {
    ...reviewRatingDimensionResponse,
    dimension: parseDimension(reviewRatingDimensionResponse.dimension),
  };
}

export function convertReviewResponseToReview(reviewResponse: ReviewResponse): Review {
  const review: Review = {
    id: reviewResponse.id,
    platform: reviewResponse.platform === 'SUNDAY' ? 'sunday' as Platform : 'google' as Platform,
    reply: getOrEmpty(reviewResponse.reply?.content),
    businessId: reviewResponse.businessId,
    creationDate: Instant.fromString(reviewResponse.creationDate, 'YYYY-MM-DDTHH:mm:ss')
      .unixInMs(),
    feedback: reviewResponse.comment ? reviewResponse.comment!.content : EMPTY,
    orderId: getOrEmpty(reviewResponse.reviewContext?.order?.id),
    tableId: EMPTY,
    paymentId: EMPTY,
    rating: reviewResponse.rating,
    totalAmount: reviewResponse.reviewContext?.billAmount,
    paidAmount: reviewResponse.reviewContext?.paidAmount,
    waiterName: getOrEmpty(reviewResponse.reviewContext?.waiter?.name),
    tableName: getOrEmpty(reviewResponse.reviewContext?.table?.name),
    replyIsPossible: reviewResponse.replyIsPossible,
    comingFromSunday: reviewResponse.comingFromSunday,
    dimensionRatings: reviewResponse.dimensions.map((reviewRatingDimensionResponse) => mapDimension(reviewRatingDimensionResponse)),
  };

  if (reviewResponse.platform === 'SUNDAY') return review;

  const complement = {
    platform: 'google' as Platform,
    author: { name: getOrEmpty(reviewResponse.author?.name) },
  };

  return { ...review, ...complement };
}
