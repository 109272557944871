/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const WarningAmberOutlined: React.FC<Props> = ({
  size = 22,
  color = themeV5.palette.warning.contrastText,
}: Props) => (
  <svg width={size} height={size} viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g id="WarningAmberOutlined" clipPath="url(#clip0_2344_32333)">
      <g id="Vector">
        <path
          d="M11.9168 8.2499C11.9168 7.74364 11.5064 7.33323 11.0001 7.33323C10.4938 7.33323 10.0834 7.74364 10.0834 8.2499V11.9166C10.0834 12.4228 10.4938 12.8332 11.0001 12.8332C11.5064 12.8332 11.9168 12.4228 11.9168 11.9166V8.2499Z"
          fill={color} />
        <path
          d="M11.0001 16.4999C11.5064 16.4999 11.9168 16.0895 11.9168 15.5832C11.9168 15.077 11.5064 14.6666 11.0001 14.6666C10.4938 14.6666 10.0834 15.077 10.0834 15.5832C10.0834 16.0895 10.4938 16.4999 11.0001 16.4999Z"
          fill={color} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.892757 17.0548C1.01132 16.7985 1.18618 16.4966 1.37859 16.1643L8.95682 3.0746C9.14847 2.74351 9.32274 2.44245 9.4855 2.21265C9.65105 1.97893 9.88896 1.69208 10.2552 1.52924C10.7294 1.31838 11.2708 1.31838 11.745 1.52924C12.1112 1.69208 12.3491 1.97893 12.5146 2.21265C12.6774 2.44244 12.8517 2.74349 13.0433 3.07457L20.6216 16.1643C20.814 16.4966 20.9888 16.7986 21.1074 17.0548C21.2279 17.3153 21.3586 17.6654 21.3169 18.065C21.263 18.5821 20.9921 19.052 20.5716 19.3577C20.2467 19.594 19.8782 19.6563 19.5924 19.6825C19.3112 19.7083 18.9623 19.7083 18.5783 19.7082H3.42181C3.03787 19.7083 2.6889 19.7083 2.40774 19.6825C2.12196 19.6563 1.75347 19.594 1.42851 19.3577C1.00802 19.052 0.737112 18.5821 0.683202 18.065C0.641541 17.6654 0.772262 17.3153 0.892757 17.0548ZM10.5434 3.99317L2.96521 17.0828C2.75829 17.4402 2.63223 17.6612 2.55666 17.8246L2.54346 17.8537L2.57525 17.8568C2.75446 17.8733 3.00891 17.8749 3.42184 17.8749H18.5784C18.9913 17.8749 19.2457 17.8733 19.4249 17.8568L19.4567 17.8537L19.4435 17.8246C19.3679 17.6613 19.242 17.4403 19.0351 17.083L11.4567 3.99313C11.2506 3.63704 11.1222 3.41856 11.0186 3.27233L11.0001 3.2466L10.9816 3.27232C10.878 3.41858 10.7496 3.63706 10.5434 3.99317Z"
              fill={color} />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2344_32333">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default WarningAmberOutlined;
