import { Business } from 'src/business/domain/Business';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { useReconcile } from 'src/operations/end-of-service/queries/useReconcile';
import { ampli } from 'src/ampli';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import { Cta } from 'src/operations/end-of-service/components/reconciliationAnalysis/PaymentsSourceOfDiscrepancies';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export const FindDiscrepanciesCaption = ({ businessId, startDate }: { businessId: string, startDate: Date }) => {
  const onClick = () => {
    ampli.reconciliationIntercomHelpClicked({
      businessId: businessId,
      reportingDate: startDate.toLocaleString(),
    });
  };
  const { formatMessage } = useIntl();


  return <Typography variant="caption">
    <FormattedMessage id={'sales_summary.reconcile.help_center.line_by_line_comparison.title'} />
    <Link
      color="text.primary"
      target={'_blank'}
      onClick={onClick}
      href={formatMessage({ id: 'sales_summary.reconcile.title.intercom.link' })}
    >
      <FormattedMessage id={'sales_summary.reconcile.title.intercom.label'} />
    </Link>
  </Typography>;
};

export const ReconciliationReportLineByLine = ({
  startDate,
  endDate,
  business,
}: {
  business: Business;
  startDate: Date;
  endDate: Date;
}) => {
  const { formatMessage } = useIntl();

  const [isFailureDialogOpen, setIsFailureDialogOpen] = React.useState(false);
  const onNoResultOrFailure = () => setIsFailureDialogOpen(true);
  const { mutateAsync, isPending } = useReconcile({
    businessId: business.id,
    startDate: startDate,
    endDate: endDate,
  });
  const onReconcile = async () => {
    try {
      ampli.reconciliationReportClicked({
        businessId: business.id,
        reportingDate: startDate.toLocaleString(),
      });
      await mutateAsync();
    } catch (e) {
      onNoResultOrFailure();
    }
  };
  const onCloseFailureDialog = () => setIsFailureDialogOpen(false);
  return (
    <>
      <Stack gap={1}>
        <FindDiscrepanciesCaption businessId={business.id} startDate={startDate} />
        <Cta
          onClick={onReconcile}
          label={formatMessage({ id: 'sales_summary.reconcile.help_center.line_by_line_comparison' })}
          icon={<AssessmentOutlinedIcon fontSize={'large'} />}
          rightSide={isPending ? <CircularProgress /> : <FileDownloadOutlinedIcon fontSize={'large'} />}
        />
      </Stack>
      <Dialog open={isFailureDialogOpen} onClose={onCloseFailureDialog}>
        <DialogTitle>
          <FormattedMessage id={'sales_summary.reconcile.error.title'} />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <FormattedMessage id={'sales_summary.reconcile.error'} />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseFailureDialog}>
            <FormattedMessage id={'sales_summary.reconcile.error.close'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
