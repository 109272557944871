import { z } from 'zod';

const BankAccountSchema = z.object({
  payoutMethod: z.literal('BankAccount'),
  iban: z.string(),
  bic: z.string(),
  name: z.string(),
  birthdate: z.coerce.date().nullable(),
  countryOfResidence: z.string().nullable(),
});

const NoneSchema = z.object({
  payoutMethod: z.literal('None'),
});

export const PayoutMethodDetailsSchema = z.discriminatedUnion('payoutMethod', [
  BankAccountSchema,
  NoneSchema,
]);

export type PayoutMethodDetails = z.infer<typeof PayoutMethodDetailsSchema>;
