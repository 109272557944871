import { useFormContext } from 'react-hook-form';
import { Address } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/Address';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Stack, Typography } from '@mui/material';

export const PrimaryAddress = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const primaryAddressErrors = errors.primaryAddress;
  const intl = useIntl();
  return <Stack direction="column" spacing={3}>
    <Typography
      variant={'h6'}>{intl.formatMessage({ id: 'settings.payments.checkout.onboarding.primaryAddress' })}</Typography>
    <Address
      addressType={'primaryAddress'}
      addressErrors={{
        addressLine1: primaryAddressErrors?.addressLine1,
        zip: primaryAddressErrors?.zip,
        city: primaryAddressErrors?.city,
        state: primaryAddressErrors?.state,
      }} register={register} />
  </Stack>;
};
