import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { GridCellParams, GridColDef, GridSortCellParams } from '@mui/x-data-grid';
import React from 'react';
import styled from 'styled-components';
import { ReviewStar } from '../component/ReviewStar';
import { RatingSkeleton } from '../component/RatingSkeleton';
import { NumberOfReviewsSkeleton } from '../component/NumberOfReviewsSkeleton';
import { fromMoneyStringToFloatNumber } from './fromMoneyStringToFloatNumber';
import { SundayPaymentsRateColumn } from './SundayPaymentsRateColumn';
import { SundayPaymentsRate } from './StaffPerformanceViewModel';
import { ReadReviews } from '../component/ReadReviews';
import { Business } from 'src/business/domain/Business';
import { Tooltip } from '@mui/material';

const HeaderCell = styled.span`
  color: #b4b4bb;
  font-size: 12px;
`;

const NbReview = styled.span`
  width: 20px;
`;

const determineReviewStarColor = (rating: number) => {
  if (rating < 7 / 3) return '#D11023';
  if (rating < 11 / 3) return '#AD5900';
  return '#336B42';
};

const sortComparatorForMoneyViewValues =
  (field: string) => (v1: any, v2: any, cellParams1: GridSortCellParams, cellParams2: GridSortCellParams) =>
    fromMoneyStringToFloatNumber(cellParams1.api.getCellValue(cellParams1.id, field)) -
    fromMoneyStringToFloatNumber(cellParams2.api.getCellValue(cellParams2.id, field));

const waiterNameColumn = (): GridColDef => ({
  field: 'waiterName',
  disableColumnMenu: true,
  minWidth: 130,
  maxWidth: 170,
  flex: 1,
  display: 'flex',
  renderHeader: () => <HeaderCell />,
});

const ratingOf5Column = (): GridColDef => ({
  field: 'ratingOutOf5',
  disableColumnMenu: true,
  flex: 1,
  display: 'flex',
  minWidth: 80,
  maxWidth: 80,
  renderCell: ({ row }: GridCellParams) => {
    const color = determineReviewStarColor(row.ratingOutOf5);
    return (
      <>
        {row.ratingOutOf5 ? (
          <>
            <ReviewStar style={{ color }} />
            {row.ratingOutOf5}
          </>
        ) : (
          <RatingSkeleton />
        )}
      </>
    );
  },
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.rating_out_of_5" />
    </HeaderCell>
  ),
});

const nbOfReviewsColumn = (business: Business): GridColDef => ({
  field: 'nbOfReviews',
  flex: 1,
  display: 'flex',
  minWidth: 90,
  maxWidth: 90,
  disableColumnMenu: true,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.nb_reviews" />
    </HeaderCell>
  ),
  renderCell: ({ row }: GridCellParams) => (
    <>
      {row.nbOfReviews ? (
        <>
          <NbReview>{row.nbOfReviews}</NbReview>
          <ReadReviews business={business} serverName={row.waiterName} />
        </>
      ) : (
        <NumberOfReviewsSkeleton />
      )}
    </>
  ),
});

const n5StarsGoogleReviewColumn = (): GridColDef => ({
  field: 'n5StarsGoogleReview',
  disableColumnMenu: true,
  flex: 1,
  display: 'flex',
  minWidth: 80,
  maxWidth: 80,
  renderCell: ({ row }: GridCellParams) => {
    return (
      <>
        {row.n5StarsGoogleReview ? (
          <>
            <NbReview>{row.n5StarsGoogleReview}</NbReview>
          </>
        ) : (
          <NumberOfReviewsSkeleton />
        )}
      </>
    );
  },
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.n_5_stars_google_review" />
    </HeaderCell>
  ),
});

const averageServiceRating = (): GridColDef => ({
  field: 'avgServiceRating',
  disableColumnMenu: true,
  flex: 1,
  display: 'flex',
  minWidth: 80,
  maxWidth: 80,
  renderCell: ({ row }: GridCellParams) => {
    const color = determineReviewStarColor(row.avgServiceRating);
    return (
      <>
        {row.avgServiceRating ? (
          <>
            <ReviewStar style={{ color }} />
            {row.avgServiceRating}
          </>
        ) : (
          <RatingSkeleton />
        )}
      </>
    );
  },
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.average_service_rating" />
    </HeaderCell>
  ),
});

const totalRevenueColumn = (): GridColDef => ({
  field: 'totalRevenue',
  disableColumnMenu: true,
  display: 'flex',
  minWidth: 90,
  maxWidth: 100,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.total_revenue" />
    </HeaderCell>
  ),
  sortComparator: sortComparatorForMoneyViewValues('totalRevenue'),
});

const basketSizeColumn = (): GridColDef => ({
  field: 'basketSize',
  disableColumnMenu: true,
  minWidth: 80,
  maxWidth: 100,
  flex: 1,
  display: 'flex',
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.basket_size" />
    </HeaderCell>
  ),
  sortComparator: sortComparatorForMoneyViewValues('basketSize'),
});

const sundayPaymentsRateColumn = (): GridColDef => ({
  field: 'sundayPaymentsRate',
  flex: 1,
  display: 'flex',
  disableColumnMenu: true,
  minWidth: 100,
  maxWidth: 170,

  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.percentage_sunday_payments" />
    </HeaderCell>
  ),
  renderCell: ({ row }: GridCellParams) => <SundayPaymentsRateColumn sundayPaymentsRate={row.sundayPaymentsRate} />,
  sortComparator: (a: SundayPaymentsRate, b: SundayPaymentsRate) => (a.rate ?? 0) - (b.rate ?? 0),
});

const nbSundayPaymentsColumn = (): GridColDef => ({
  field: 'nbSundayPayments',
  flex: 1,
  display: 'flex',
  disableColumnMenu: true,
  minWidth: 100,
  maxWidth: 100,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.nb_sunday_payments" />
    </HeaderCell>
  ),
});

const totalTipsColumn = (): GridColDef => ({
  field: 'totalTips',
  flex: 1,
  display: 'flex',
  minWidth: 100,
  maxWidth: 100,
  disableColumnMenu: true,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.total_tips" />
    </HeaderCell>
  ),
  sortComparator: sortComparatorForMoneyViewValues('totalTips'),
});

const tipsRateColumn = (): GridColDef => ({
  field: 'tipsRate',
  flex: 1,
  display: 'flex',
  minWidth: 100,
  maxWidth: 100,
  disableColumnMenu: true,
  renderHeader: () => (
    <Tooltip title={<FormattedMessage id="staff_performance.sunday_tips_rate_description" />} placement="right">
      <HeaderCell>
        <FormattedMessage id="staff_performance.sunday_tips_rate" />
      </HeaderCell>
    </Tooltip>
  ),
});

const nbOfWalkoutsColumn = (): GridColDef => ({
  field: 'nbOfWalkouts',
  flex: 1,
  display: 'flex',
  minWidth: 100,
  maxWidth: 100,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.walkouts.number" />
    </HeaderCell>
  ),
});

const walkoutSalesAmountColumn = (): GridColDef => ({
  field: 'walkoutsSalesAmount',
  flex: 1,
  display: 'flex',
  minWidth: 100,
  maxWidth: 100,
  renderHeader: () => (
    <HeaderCell>
      <FormattedMessage id="staff_performance.walkouts.cost" />
    </HeaderCell>
  ),
  sortComparator: sortComparatorForMoneyViewValues('walkoutsSalesAmount'),
});

const billViewRateColumn = (): GridColDef => ({
  field: 'billViewRate',
  flex: 1,
  display: 'flex',
  minWidth: 110,
  maxWidth: 110,
  disableColumnMenu: true,
  renderHeader: () => (
    <Tooltip title={<FormattedMessage id="staff_performance.bill_view_rate_description" />} placement="right">
      <HeaderCell>
        <FormattedMessage id="staff_performance.bill_view_rate" />
      </HeaderCell>
    </Tooltip>
  ),
});

export const buildColumns = (isUSBusiness: boolean, business: Business): GridColDef[] => {
  const walkoutColumns: GridColDef[] = isUSBusiness ? [
    nbOfWalkoutsColumn(),
    walkoutSalesAmountColumn(),
  ] : [];

  return [
    waiterNameColumn(),
    ratingOf5Column(),
    nbOfReviewsColumn(business),
    n5StarsGoogleReviewColumn(),
    averageServiceRating(),
    totalRevenueColumn(),
    basketSizeColumn(),
    sundayPaymentsRateColumn(),
    nbSundayPaymentsColumn(),
    ...walkoutColumns,
    totalTipsColumn(),
    tipsRateColumn(),
    billViewRateColumn(),
  ].filter((col) => !isUSBusiness || col.field !== 'tipsRate');
};
