import { useFormContext } from 'react-hook-form';
import { Address } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/Address';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Alert, Stack, Typography } from '@mui/material';

export const RegisteredBusinessAddress = ({ businessCountryCode }: { businessCountryCode: string }) => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const registeredAddressErrors = errors.registeredAddress;
  const intl = useIntl();
  return <Stack direction="column" spacing={3}>
    <Typography
      variant={'h6'}>{intl.formatMessage({ id: 'settings.payments.checkout.onboarding.registeredAddress' })}</Typography>
    {
      businessCountryCode === 'GB' && <Alert severity="info">
        <Typography>
          <FormattedMessage id={'settings.payments.checkout.onboarding.registered_address.helper_text'} />
        </Typography>
      </Alert>
    }
    <Address
      addressType={'registeredAddress'}
      addressErrors={{
        addressLine1: registeredAddressErrors?.addressLine1,
        zip: registeredAddressErrors?.zip,
        city: registeredAddressErrors?.city,
        state: registeredAddressErrors?.state,
      }} register={register} />
  </Stack>;
};
