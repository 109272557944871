import { CurrencyCode, Money } from '@sundayapp/web-money';
import { CategoryDto, ElementSummaryDto, MenuDto, MenuSummaryDto, ScheduleRangeDto, SchedulesDto } from './MenuDto';
import { MoneyDto } from './ProductDto';
import { Schedules } from '../domain/Schedules';
import { Days } from '../domain/Days';
import { ScheduleRange } from '../domain/ScheduleRange';
import { Category, ElementSummary } from '../domain/Category';
import { MenuSummary } from '../domain/MenuSummary';
import { Menu } from '../domain/Menu';
import { BlockDtoConverter } from './block/BlockDtoConverter';
import { ProductDtoConverter } from './ProductDtoConverter';
import { productTypes } from '../domain/Product';

export class MenuDtoConverter {
  static toMenuSummary(menuDto: MenuSummaryDto): MenuSummary {
    return {
      id: menuDto.id,
      name: menuDto.name,
      displayName: menuDto.displayName,
      enabled: menuDto.enabled,
      lastModifiedAt: menuDto.lastModifiedAt,
      schedules: this.convertScheduleToDomain(menuDto.schedules),
      sundayMenu: menuDto.sundayMenu,
      posConnection: menuDto.posConnection || null,
      imageUrl: menuDto.imageUrl,
    };
  }

  static toMenu(menuDto: MenuDto): Menu {
    return {
      id: menuDto.id,
      name: menuDto.name,
      displayName: menuDto.displayName,
      enabled: menuDto.enabled,
      lastModifiedAt: menuDto.lastModifiedAt,
      sundayMenu: menuDto.sundayMenu,
      schedules: this.convertScheduleToDomain(menuDto.schedules),
      categories: this.toCategories(menuDto.categories),
      externalId: menuDto.externalId,
      imageUrl: menuDto.imageUrl,
      description: menuDto.description,
      shortDescription: menuDto.shortDescription,
      productPriceOverrides: menuDto.productPriceOverrides,
      posConnection: menuDto.posConnection || null,
    };
  }

  static toCategories(categoriesDto: CategoryDto[]): Category[] {
    if (categoriesDto === undefined) {
      return [];
    }

    return categoriesDto.map((dto) => ({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      elements: this.toElementSummaries(dto.elements),
    }));
  }

  static toElementSummaries(elements: ElementSummaryDto[]): ElementSummary[] {
    if (elements === undefined) {
      return [];
    }
    return elements.map((dto) => this.mapToElement(dto));
  }

  private static mapToElement(dto: ElementSummaryDto) {
    switch (dto.type) {
      case 'BLOCK':
        return BlockDtoConverter.toBlock(dto);
      case productTypes.PRODUCT:
      case productTypes.BUNDLE:
      case productTypes.MODIFIER_GROUP:
      case productTypes.MODIFIER:
      case productTypes.VARIANT_GROUP:
      case productTypes.VARIANT:
        return ProductDtoConverter.toProductSummary(dto);
    }
  }

  static fromSchedules(schedules: Schedules): SchedulesDto {
    return {
      ranges: schedules.ranges.map((range) => MenuDtoConverter.convertScheduleRangeToDto(range)),
    };
  }

  private static fromMoney(price: MoneyDto): Money {
    return {
      amount: price.amount,
      currency: (<any>CurrencyCode)[price.currency],
    };
  }

  private static convertScheduleRangeToDto(range: ScheduleRange): ScheduleRangeDto {
    return {
      day: Days[range.day],
      startDate: MenuDtoConverter.dateToDto(range.startTime),
      endDate: MenuDtoConverter.dateToDto(range.endTime),
    };
  }

  private static convertScheduleToDomain(dto: SchedulesDto): Schedules {
    if (dto === undefined) {
      return { ranges: [] };
    }

    if (dto.ranges.length === 0) {
      return {
        ranges: [],
      };
    }

    return {
      ranges: dto.ranges
        .map((range) => {
          const day: Days = (<any>Days)[range.day];
          return {
            day,
            startTime: this.dateToDomain(range.startDate),
            endTime: this.dateToDomain(range.endDate),
          } as ScheduleRange;
        })
        .sort((a, b) => a.day.valueOf() - b.day.valueOf()),
    };
  }

  private static dateToDomain(date: string) {
    if (date === '23:59:59') {
      return '24:00';
    }

    return date.substring(0, 5);
  }

  private static dateToDto(date: string) {
    if (date === '24:00') {
      return '23:59:59';
    }
    return `${date}:00`;
  }
}
