import { BusinessId } from 'src/business/domain/Business';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { ReportContainer } from 'src/operations/end-of-service/components/reconciliationAnalysis/ReportContainer';
import { Stack } from '@mui/material';
import {
  FindDiscrepanciesCaption,
} from 'src/operations/end-of-service/components/reconciliationAnalysis/ReconciliationReportLineByLine';
import { Cta } from 'src/operations/end-of-service/components/reconciliationAnalysis/PaymentsSourceOfDiscrepancies';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';

export const ReconciliationAnalysisAction = ({
  businessId,
  onClick,
  startDate,
}: {
  businessId: BusinessId;
  startDate: Date;
  onClick: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <ReportContainer
      title={<FormattedMessage id="sales_summary.reconcile.help_center.title" />}
      withDiscrepancies={false}
    >
      <Stack gap={1}>
        <FindDiscrepanciesCaption businessId={businessId} startDate={startDate} />
        <Cta
          onClick={onClick}
          label={formatMessage({ id: 'sales_summary.reconcile.help_center.analyse' })}
          icon={<TroubleshootIcon fontSize={'large'} />}
          rightSide={<ChevronRightIcon fontSize={'large'} />}
        />
      </Stack>
    </ReportContainer>
  );
};
