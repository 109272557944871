import axios, { AxiosStatic } from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import { SummaryOnAPeriod } from './SummaryOnAPeriod';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ShiftsResponse } from './ShiftsResponse';
import { Shift } from '../domain/Shift';
import { ShiftsResponseMapper } from './ShiftsResponseMapper';
import { ShiftPayloadMapper } from './ShiftPayloadMapper';
import { BusinessId } from 'src/business/domain/Business';
import { isRevenuesSummary, RevenuesSummary } from '../../revenues/domain/RevenueSummary';

type RevenueSummaryQueryCancelled = {
  code: 'CANCELLED';
};

export class AccountingRepository {
  private readonly accountingApiBaseUrl: string;

  private readonly merchantAccountsApiBaseUrl: string;

  private static revenueSummaryAbortController: AbortController = new AbortController();

  constructor(private httpClient: AxiosStatic) {
    const configuration = ConfigurationLoader.load();
    this.merchantAccountsApiBaseUrl = configuration.merchantAccountsBaseUrl;
    this.accountingApiBaseUrl = configuration.accountingApiBaseUrl;
  }

  async summaryOnAPeriod(businessId: BusinessId, startDate: Date, endDate: Date): Promise<SummaryOnAPeriod> {
    const response = await this.httpClient.get<SummaryOnAPeriod>(
      `${this.accountingApiBaseUrl}/accounting/venues/${businessId}/revenue-summary`, {
        headers: {
          contentType: 'application/json',
        },
        params: {
          from: startDate,
          to: endDate,
        },
      });

    return response.data;
  }

  async getRevenuesSummary(businessId: BusinessId, startDate: String, endDate: String): Promise<RevenuesSummary | RevenueSummaryQueryCancelled> {
    AccountingRepository.revenueSummaryAbortController.abort();
    AccountingRepository.revenueSummaryAbortController = new AbortController();

    return this.httpClient.get<RevenuesSummary>(`${this.accountingApiBaseUrl}/accounting/venues/${businessId}/revenue-summary-v3`, {
      signal: AccountingRepository.revenueSummaryAbortController.signal,
      headers: {
        contentType: 'application/json',
      },
      params: {
        from: startDate,
        to: endDate,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return isRevenuesSummary(response.data) ? response.data : { revenues: [] };
        }
        datadogLogs.logger.error(`Unable to retrieve revenues summary : response ${response.status} from server`, {
          businessId,
          startDate,
          endDate,
          response,
        });
        return { revenues: [] };
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return { code: 'CANCELLED' };
        }

        datadogLogs.logger.error(`Unable to retrieve revenues summary, error ${error.code}`, {
          businessId,
          startDate,
          endDate,
          error,
        });
        return { revenues: [] };
      });

  }

  async getShiftsByVenue(businessId: BusinessId): Promise<Shift[]> {
    const response = await axios.get<ShiftsResponse>(`${this.merchantAccountsApiBaseUrl}/v2/venues/${businessId}/accounting/shifts`, {
      headers: {
        contentType: 'application/json',
      },
    });
    return ShiftsResponseMapper.toShift(response.data);
  }

  async saveShiftsByVenue(businessId: BusinessId, updatedShifts: Shift[]): Promise<void> {
    await axios.put<ShiftsResponse>(`${this.merchantAccountsApiBaseUrl}/v2/venues/${businessId}/accounting/shifts`,
      ShiftPayloadMapper.toShiftPayload(updatedShifts),
      {
        headers: {
          contentType: 'application/json',
        },
      });
  }
}
