import React, { useState } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { MoneyView } from '@sundayapp/web-money';
import { Business } from 'src/business/domain/Business';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { DesktopWindowsOutlined } from '@mui/icons-material';
import { FastPaymentIcon } from 'src/operations/end-of-service/components/FastPaymentIcon';
import { ampli } from 'src/ampli';
import {
  PaymentsSourcesOfDiscrepanciesDetailsModal,
} from 'src/operations/end-of-service/components/reconciliationAnalysis/PaymentsSourcesOfDiscrepanciesDetailsModal';
import {
  amountOfSales,
  NonReconciledPayment,
  ReconciliationReport,
} from 'src/operations/end-of-service/model/NonReconciledPayment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const CtaContainer = styled(Box)(({ isClickable }: { isClickable: boolean }) => ({
  minHeight: '66px',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#FFFFFF',
  borderRadius: '16px',
  border: '1px solid rgba(14, 7, 29, 0.14)',
  padding: '12px 24px',
  cursor: isClickable ? 'pointer' : 'default',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: isClickable ? '#F4F6FF' : '#FFFFFF',
  },
}));
const LeftSide = ({ children }: { children: React.ReactNode }) => (
  <Stack spacing={1} alignItems={'center'} direction={'row'}>
    {children}
  </Stack>
);
const RightSide = ({ children }: { children: React.ReactNode }) => (
  <Stack minWidth={56} spacing={5} alignItems={'center'} justifyContent={'flex-end'} direction={'row'}>
    {children}
  </Stack>
);
export const Cta = ({
  onClick,
  label,
  icon,
  rightSide,
}: {
  label: string;
  icon?: React.ReactNode;
  rightSide: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <CtaContainer isClickable={!!onClick} onClick={onClick} flexDirection={'row'}>
      <LeftSide>
        {icon}
        <Typography fontWeight={500} variant={'body1'}>
          {label}
        </Typography>
      </LeftSide>
      <RightSide>
        {rightSide}
      </RightSide>
    </CtaContainer>
  );
};

const FailedTobeNotifiedPayments = ({
  business,
  startDate,
  failedToBeNotifiedPayments,
}: {
  business: Business;
  startDate: Date;
  failedToBeNotifiedPayments: NonReconciledPayment[];
}) => {
  const [openFailedToBeNotifiedDetails, setOpenFailedToBeNotifiedDetails] = useState(false);

  const nbOfFailedToBeNotifiedPayments = failedToBeNotifiedPayments.length;
  const failedToBeNotifiedAmount = amountOfSales(failedToBeNotifiedPayments, business.currency); //
  const onClickFailedToBeNotifiedPayments = () => {
    ampli.paymentsFailedToBeNotifiedClicked({
      businessId: business.id,
      reportingDate: startDate.toLocaleString(),
    });
    setOpenFailedToBeNotifiedDetails(true);
  };
  const { formatMessage } = useIntl();

  return (
    <>
      <Cta
        onClick={onClickFailedToBeNotifiedPayments}
        label={formatMessage({ id: 'sales_summary.reconcile.help_center.failed_to_be_notified_operations' })}
        icon={<DesktopWindowsOutlined fontSize={'large'} />}
        rightSide={
          <Stack gap={5} direction={'row'} alignItems="center">
            <Stack alignItems={'flex-end'}>
              <Typography fontWeight={500}>
                <MoneyView value={failedToBeNotifiedAmount} />
              </Typography>
              <Typography variant={'caption'}>
                <FormattedMessage
                  id={`sales_summary.reconcile.help_center.operations${nbOfFailedToBeNotifiedPayments > 1 ? '_plural' : ''}`}
                  values={{ value: nbOfFailedToBeNotifiedPayments }}
                />
              </Typography>
            </Stack>
            <ChevronRightIcon fontSize={'large'} />
          </Stack>
        }
      />
      {openFailedToBeNotifiedDetails && (
        <PaymentsSourcesOfDiscrepanciesDetailsModal
          onClose={() => setOpenFailedToBeNotifiedDetails(false)}
          paymentsSourceOfDiscrepancies={failedToBeNotifiedPayments}
          headerTitle={formatMessage(
            {
              id: `sales_summary.reconcile.help_center.failed_to_be_notified_operations.header${(nbOfFailedToBeNotifiedPayments ?? 0 > 1) ? '_plural' : ''}`,
            },
            { nbOfPayments: nbOfFailedToBeNotifiedPayments },
          )}
        />
      )}
    </>
  );
};

const PDQPaymentsWithoutTableAssociated = ({
  startDate,
  business,
  paymentsWithoutTableAssociated,
}: {
  business: Business;
  startDate: Date;
  paymentsWithoutTableAssociated: NonReconciledPayment[];
}) => {
  const [openPDQPaymentWithoutTableAssociated, setOpenPDQPaymentWithoutTableAssociated] = useState(false);
  const nbOfPaymentsPDQWithoutAssociatedTable = paymentsWithoutTableAssociated.length;
  const pdqFastPaymentsAmount = amountOfSales(paymentsWithoutTableAssociated, business.currency);

  const { formatMessage } = useIntl();

  const onClickPDQPaymentsWithoutTableAssociated = () => {
    ampli.pdqPaymentsWithoutTableAssociatedClicked({
      businessId: business.id,
      reportingDate: startDate.toLocaleString(),
    });
    setOpenPDQPaymentWithoutTableAssociated(true);
  };
  return (
    <>
      <Cta
        onClick={onClickPDQPaymentsWithoutTableAssociated}
        label={formatMessage({ id: 'sales_summary.reconcile.help_center.fast_payments' })}
        icon={<FastPaymentIcon fontSize={'large'} />}
        rightSide={
          <Stack gap={5} direction={'row'} alignItems="center">
            <Stack alignItems={'flex-end'}>
              <Typography fontWeight={500}>
                <MoneyView value={pdqFastPaymentsAmount} />
              </Typography>
              <Typography variant={'caption'}>
                <FormattedMessage
                  id={`sales_summary.reconcile.help_center.operations${nbOfPaymentsPDQWithoutAssociatedTable > 1 ? '_plural' : ''}`}
                  values={{ value: nbOfPaymentsPDQWithoutAssociatedTable }}
                />
              </Typography>
            </Stack>
            <ChevronRightIcon fontSize={'large'} />
          </Stack>
        }
      />
      {openPDQPaymentWithoutTableAssociated && (
        <PaymentsSourcesOfDiscrepanciesDetailsModal
          onClose={() => setOpenPDQPaymentWithoutTableAssociated(false)}
          paymentsSourceOfDiscrepancies={paymentsWithoutTableAssociated}
          headerTitle={formatMessage(
            {
              id: `sales_summary.reconcile.help_center.fast_payments.header${(nbOfPaymentsPDQWithoutAssociatedTable ?? 0 > 1) ? '_plural' : ''}`,
            },
            { nbOfPayments: nbOfPaymentsPDQWithoutAssociatedTable },
          )}
        />
      )}
    </>
  );
};

export const PaymentsSourceOfDiscrepancies = ({
  startDate,
  business,
  reconciliationReport,
}: {
  startDate: Date;
  business: Business;
  reconciliationReport: ReconciliationReport
}) => {

  const nbOfFailedToBeNotifiedPayments = reconciliationReport.nonNotifiedPayments.length;
  const nbFastPayments = reconciliationReport.fastPaymentPdq.length;

  if (nbFastPayments <= 0 && nbOfFailedToBeNotifiedPayments <= 0) {
    return <></>;
  }
  return (
    <>
      <Stack gap={1}>
        <Typography variant={'caption'}>
          <FormattedMessage id="sales_summary.reconcile.help_center.discrepancyDetected.subtitle" />
        </Typography>
        {nbOfFailedToBeNotifiedPayments > 0 && (
          <FailedTobeNotifiedPayments
            business={business}
            startDate={startDate}
            failedToBeNotifiedPayments={reconciliationReport.nonNotifiedPayments}
          />
        )}
        {nbFastPayments > 0 && (
          <PDQPaymentsWithoutTableAssociated
            business={business}
            startDate={startDate}
            paymentsWithoutTableAssociated={reconciliationReport.fastPaymentPdq}
          />
        )}
      </Stack>
    </>
  );
};
