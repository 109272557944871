// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';
import { translation } from 'src/accounting/common/translation';
import { DatePresets } from 'src/components/time-frame-filter/TimeFrameFilter';


export const datePresetKeys = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7_DAYS_FILTER: 'LAST_7_DAYS_FILTER',
  LAST_WEEK_FILTER: 'LAST_WEEK_FILTER',
  MONTH_TO_DATE_FILTER: 'MONTH_TO_DATE_FILTER',
  LAST_MONTH_FILTER: 'LAST_MONTH_FILTER',
  CURRENT_YEAR_FILTER: 'CURRENT_YEAR_FILTER',
  PREVIOUS_YEAR_FILTER: 'PREVIOUS_YEAR_FILTER',
  CUSTOM: 'CUSTOM',
} as const;

export function datePresets(
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  momentProvider = () => moment(),
): DatePresets<typeof datePresetKeys> {
  const now = (): Moment => momentProvider().tz(timezone);

  const isEndOfLastShift = (aMoment: Moment) => aMoment.hour() < 4 && aMoment.hour() >= 0;
  const getCurrentShiftDate = (aMoment: Moment = now()) => (isEndOfLastShift(aMoment) ? aMoment.subtract(1, 'day') : aMoment);
  const shiftStartOf = (aMoment: Moment) => aMoment.hour(4);
  const shiftEndOf = (aMoment: Moment) => aMoment.hour(3).minute(59).second(59);

  return ({
    [datePresetKeys.TODAY]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()
          .startOf('day')),
        endDate: shiftEndOf(getCurrentShiftDate()
          .add(1, 'days')),
      },
      translation: translation('accounting.revenue_tab.today_filter'),
    },
    [datePresetKeys.YESTERDAY]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()
          .subtract(1, 'd')
          .startOf('day')),
        endDate: shiftEndOf(getCurrentShiftDate()),
      },
      translation: translation('accounting.revenue_tab.yesterday_filter'),
    },
    [datePresetKeys.LAST_7_DAYS_FILTER]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()
          .subtract(7, 'd')
          .startOf('day')),
        endDate: shiftEndOf(getCurrentShiftDate()),
      },
      translation: translation('accounting.payout_tab.last_7_days_filter'),
    },
    [datePresetKeys.LAST_WEEK_FILTER]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()
          .subtract(1, 'week')
          .startOf('isoWeek')
          .startOf('day')),
        endDate: shiftEndOf(getCurrentShiftDate()
          .subtract(1, 'week')
          .endOf('isoWeek')
          .endOf('day')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.last_week_filter'),
    },
    [datePresetKeys.MONTH_TO_DATE_FILTER]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()
          .startOf('month')
          .startOf('day')),
        endDate: shiftEndOf(getCurrentShiftDate()
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.month_to_date_filter'),
    },
    [datePresetKeys.LAST_MONTH_FILTER]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()
          .subtract(1, 'month')
          .startOf('month')
          .startOf('day')),
        endDate: shiftEndOf(getCurrentShiftDate()
          .subtract(1, 'month')
          .endOf('month')
          .endOf('day')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.last_month_filter'),
    },
    [datePresetKeys.CURRENT_YEAR_FILTER]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()
          .startOf('year')
          .startOf('day')),
        endDate: shiftEndOf(getCurrentShiftDate()
          .endOf('day')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.current_year_filter', {
        currentYear: getCurrentShiftDate()
          .year(),
      }),
    },
    [datePresetKeys.PREVIOUS_YEAR_FILTER]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()
          .subtract(1, 'year')
          .startOf('year')
          .startOf('day')),
        endDate: shiftEndOf(getCurrentShiftDate()
          .subtract(1, 'year')
          .endOf('year')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.previous_year_filter', {
        previousYear: getCurrentShiftDate()
          .subtract(1, 'year')
          .year(),
      }),
    },
    [datePresetKeys.CUSTOM]: {
      range: {
        startDate: shiftStartOf(getCurrentShiftDate()),
        endDate: shiftEndOf(getCurrentShiftDate()),
      },
      translation: translation('accounting.revenue_tab.custom_filter'),
    },
  });
}

