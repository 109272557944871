import React, { ReactNode } from 'react';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { UserRole } from 'src/domain/user/UserRole';
import { isAuthorized } from 'src/domain/user/Role';
import { CountryCode } from 'src/domain/CountryCode';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

interface Props {
  authorizedRoles: Array<UserRole>;
  availableIn?: (keyof typeof CountryCode)[];
  children: ReactNode;
}

export const WithRestrictedAccess: React.FC<Props> = ({ authorizedRoles, children, availableIn }: Props) => {
  const user = useAuthenticatedUserOrThrow();
  const business = useCurrentBusinessOrThrow();
  if (
    isAuthorized(user.claims, authorizedRoles, business.id) &&
    (!availableIn || availableIn.includes(business.address.countryCode))
  ) {
    return <>{children}</>;
  }
  return <div>Unauthorized access</div>;
};

export function withRestrictedAccess(
  authorizedRoles: Array<UserRole>,
  WrappedComponent: React.ComponentType,
): React.ComponentType {
  const wrapped = (props: object) => (
    <WithRestrictedAccess authorizedRoles={authorizedRoles}>
      <WrappedComponent {...props} />
    </WithRestrictedAccess>
  );
  wrapped.displayName = 'WithRestrictedAccessHOC';
  return wrapped;
}
