import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';

export const MenuItemColumn = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-shrink: 0;
  padding-top: ${spaceUsages.medium};
  padding-bottom: ${spaceUsages.medium};
`;
