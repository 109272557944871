import { TextFieldWithLabel } from 'src/menu/dynamic-menu/pages/MenuDetailsPage/components/TextFieldWithLabel';
import React, { useRef, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { MenuFormData } from './MenuDetailsPanel';
import { Menu } from 'src/menu/dynamic-menu/domain/Menu';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { flushSync } from 'react-dom';
import { LocalisationKey } from 'src/lang/en';

type Props = {
  register: UseFormRegister<MenuFormData>,
  menu: Menu,
  errors: FieldErrors<MenuFormData>
};

export function MenuNameSettings({ register, menu, errors }: Props) {
  const isReadonlyName = !menu.sundayMenu;

  const [showDisplayName, setShowDisplayName] = useState(!!menu.displayName);
  const displayNameRef = useRef<HTMLDivElement>(null);

  function handleNameClick() {
    if (isReadonlyName && displayNameRef.current) {
      flushSync(() => {
        setShowDisplayName(true);
      });
      displayNameRef.current.focus();
    }
  }

  const posNameLabel: LocalisationKey = showDisplayName ? 'menus.settings.pos_name.label' :  'menus.settings.name.label';

  return (
    <>
      <TextFieldWithLabel inputRef={displayNameRef}
                          registerProps={register('displayName')}
                          labelKey={'menus.settings.display_name.label'}
                          placeholderKey={'menus.settings.display_name.placeholder'}
                          helperText={errors.name?.message}
                          sx={{ display: !showDisplayName ? 'none' : undefined }}
      />
      <TextFieldWithLabel registerProps={register('name')}
                          labelKey={posNameLabel}
                          placeholderKey={'menus.settings.name.placeholder'}
                          helperText={errors.name?.message}
                          required={true}
                          slotProps={{
                            input: {
                              readOnly: isReadonlyName,
                            },
                          }}
                          onClick={handleNameClick}
      />
    </>);
}
