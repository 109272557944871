import take from 'lodash/take';
import drop from 'lodash/drop';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { ArrowIcon, colorPalette, IconButton, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text } from '@sundayapp/b2b-react-component-library';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { BillToDisplay } from './BillToDisplay';
import { TabCaptureConfirmation } from '../component/TabCaptureConfirmation';
import { BillTableRow } from './BillTableRow';
import { hasTabBillsToDisplay } from './hasTabBillsToDisplay';
import { Money } from '@sundayapp/web-money';
import { Instant } from 'src/sundayplus/Instant';
import { BusinessId } from 'src/business/domain/Business';

const BillsDataTable = styled(Table)`
  ${TableCell} {
    padding: 9px 0 9px 16px;
    font-size: 16px;
    line-height: normal;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  margin: 5px 0 0 0;
`;

const CurrentPage = styled(Text)`
  margin-right: 8px;
  color: ${colorPalette.grey400};
`;

const BillTableHeader = styled(TableHeader)`
  white-space: pre;
  padding: 1px 1px 1px 16px;
`;

export type BillsTableProps = {
  bills: BillToDisplay[];
  hasCheckNumbers: boolean;
  currentPage: number;
  pageCount: number;
  previousPage: () => void;
  nextPage: () => void;
  refreshBill: (billId: string, isTab: boolean) => Promise<void>;
  refetch: () => void;
};

export const BILLS_PER_PAGE = 20;

type TabToBeCaptured = {
  tabId: string;
  businessId: BusinessId;
  leftToPay: Money;
  digitalFeeRate: number
};

export interface BillFreshness {
  stalled: boolean;
  tooOld: boolean;
}

function compute(billsToDisplay: BillToDisplay[]): Record<string, BillFreshness> {
  const fun = (acc: Record<string, BillFreshness>, b: BillToDisplay) => {
    return {
      ...acc,
      [b.billId]: {
        stalled: b.updateAt?.isBefore(Instant.now()
          .subtract(5, 'minutes')) ?? false,
        tooOld: (!!b.tab
          ? b.issuedAt?.isBefore(Instant.now()
            .subtract(15, 'h'))
          : b.issuedAt?.isBefore(Instant.now()
            .subtract(6, 'h'))) ?? false,
      },
    };
  };
  return billsToDisplay.reduce(fun, {});
}

export const BillsTable = ({
  bills,
  hasCheckNumbers,
  currentPage,
  pageCount,
  previousPage,
  nextPage,
  refreshBill,
  refetch,
}: BillsTableProps) => {
  function getBillsToDisplay() {
    return take(drop(bills, (currentPage - 1) * BILLS_PER_PAGE), BILLS_PER_PAGE);
  }

  const billsToDisplay: BillToDisplay[] = getBillsToDisplay();
  const [billFreshnesses, setBillFreshnesses] = useState<Record<string, BillFreshness>>({});
  const freshnessTimeout = useRef<number | undefined>(undefined);

  const fun = (billsToBeDisplayed: BillToDisplay[]) => {
    setBillFreshnesses(compute(billsToBeDisplayed));
    if (freshnessTimeout.current) {
      window.clearTimeout(freshnessTimeout.current);
    }
    freshnessTimeout.current = window.setTimeout(() => {
      fun(getBillsToDisplay());
    }, 1000);
  };

  useEffect(() => {
    freshnessTimeout.current = window.setTimeout(() => {
      fun(getBillsToDisplay());
    }, 1000);
    return () => {
      if (!!freshnessTimeout.current) {
        window.clearTimeout(freshnessTimeout.current);
      }
    };
  }, [bills]);

  const hasTab = hasTabBillsToDisplay(bills, currentPage, BILLS_PER_PAGE);

  const [tabToBeCaptured, setTabToBeCaptured] = useState<TabToBeCaptured | undefined>(undefined);

  return (
    <>
      <BillsDataTable size="small" width="100%">
        <TableHead>
          <TableRow>
            <BillTableHeader>
              {hasTab ? (
                <FormattedMessage id="orders.table.table_number_or_tab" />
              ) : (
                <FormattedMessage id="orders.table.table_number" />
              )}
            </BillTableHeader>
            {hasCheckNumbers && (
              <BillTableHeader>
                <FormattedMessage id="orders.table.check_number" />
              </BillTableHeader>
            )}
            <BillTableHeader>
              <FormattedMessage id="orders.table.duration" />
            </BillTableHeader>
            <BillTableHeader>
              <FormattedMessage id="orders.table.waiter_name" />
            </BillTableHeader>
            <BillTableHeader textAlign="end">
              <FormattedMessage id="orders.table.status" />
            </BillTableHeader>
            <BillTableHeader textAlign="end">
              <FormattedMessage id="orders.table.total" />
            </BillTableHeader>
            <BillTableHeader textAlign="center">
              <FormattedMessage id="orders.table.ticket" />
            </BillTableHeader>
            <BillTableHeader textAlign="center">
              <FormattedMessage id="orders.table.payments" />
            </BillTableHeader>
            <BillTableHeader textAlign="center">
              <FormattedMessage id="orders.table.refresh" />
            </BillTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {billsToDisplay.map((bill) => (
            <TableRow data-testid={bill.billId} key={bill.billId}>
              <BillTableRow
                bill={bill}
                hasCheckNumbers={hasCheckNumbers}
                onCaptureClick={() =>
                  setTabToBeCaptured({
                    tabId: bill.tab!.id,
                    businessId: bill.order.businessId,
                    leftToPay: bill.leftToPay,
                    digitalFeeRate: bill.tab!.nominalDigitalFeeRateForCapture,
                  })
                }
                refreshBill={refreshBill}
                freshness={billFreshnesses[bill.billId] ?? {
                  tooOld: false,
                  stalled: false,
                }}
              />
            </TableRow>
          ))}
        </TableBody>
      </BillsDataTable>
      {pageCount > 1 && (
        <Navigation>
          <CurrentPage size="medium">
            page {currentPage}
            {' of '}
            {pageCount}
          </CurrentPage>
          <IconButton
            data-testid="previousButton"
            size="small"
            onClick={previousPage}
            disabled={currentPage === 1}
            variant="default"
          >
            <ArrowIcon direction="left" />
          </IconButton>
          <IconButton
            data-testid="nextButton"
            size="small"
            onClick={nextPage}
            disabled={currentPage === pageCount}
            variant="default"
          >
            <ArrowIcon direction="right" />
          </IconButton>
        </Navigation>
      )}
      {tabToBeCaptured && (
        <TabCaptureConfirmation
          show={!!tabToBeCaptured}
          onCaptureSuccess={() => refetch()}
          onCancel={() => setTabToBeCaptured(undefined)}
          billLeftToPay={tabToBeCaptured.leftToPay}
          businessId={tabToBeCaptured.businessId}
          tabId={tabToBeCaptured.tabId}
          digitalFeeRate={tabToBeCaptured.digitalFeeRate}
        />
      )}
    </>
  );
};
