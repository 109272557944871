import { Business } from 'src/business/domain/Business';
import { RestaurantShiftTimesOptions } from 'src/operations/restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { RestaurantShiftTimesOption } from 'src/operations/restaurant-shifts/models/RestaurantShiftTimesOption';
import React, { useEffect } from 'react';
import { getStartAndEndDateForChosenDateAndShiftOptions } from 'src/operations/end-of-service/hooks/getStartAndEndDateForChosenDateAndShiftOptions';
import { ReconciliationAnalysis } from 'src/operations/end-of-service/components/reconciliationAnalysis/ReconciliationAnalysis';
import { ampli } from 'src/ampli';
import { ReconciliationAnalysisAction } from 'src/operations/end-of-service/components/reconciliationAnalysis/ReconciliationAnalysisAction';

export const ReconciliationAnalysisPanel = ({
  business,
  reportingDate,
  options,
  restaurantShiftTimesOption,
}: {
  business: Business;
  reportingDate: Date;
  options: RestaurantShiftTimesOptions;
  restaurantShiftTimesOption: RestaurantShiftTimesOption;
}) => {
  const [analyseDiscrepancies, setAnalyseDiscrepancies] = React.useState(false);
  const { startAtLocalBusiness, endAtLocalBusiness } = getStartAndEndDateForChosenDateAndShiftOptions(
    options,
    restaurantShiftTimesOption,
    reportingDate,
    business,
  );

  const resetAnalysis = () => setAnalyseDiscrepancies(false);

  useEffect(resetAnalysis, [business, reportingDate, options, restaurantShiftTimesOption]);

  const onAnalyse = () => {
    ampli.reconciliationAnalysisClicked({
      businessId: business.id,
      reportingDate: startAtLocalBusiness.toLocaleString(),
    });
    setAnalyseDiscrepancies(true);
  };

  if (!analyseDiscrepancies) {
    return <ReconciliationAnalysisAction businessId={business.id} startDate={startAtLocalBusiness} onClick={onAnalyse} />;
  }
  return <ReconciliationAnalysis business={business} startDate={startAtLocalBusiness} endDate={endAtLocalBusiness} />;
};
