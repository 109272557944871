import { CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { Business } from 'src/business/domain/Business';
import {
  PaymentsSourceOfDiscrepancies,
} from 'src/operations/end-of-service/components/reconciliationAnalysis/PaymentsSourceOfDiscrepancies';
import { useReconciliationReport } from 'src/operations/end-of-service/queries/useFailedToBeNotifiedPayments';
import {
  ReconciliationReportLineByLine,
} from 'src/operations/end-of-service/components/reconciliationAnalysis/ReconciliationReportLineByLine';
import { ReconciliationReport } from 'src/operations/end-of-service/model/NonReconciledPayment';
import { ReportContainer } from 'src/operations/end-of-service/components/reconciliationAnalysis/ReportContainer';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const ReconciliationReportWithoutDiscrepancies = (props: {
  business: Business;
  startDate: Date;
  reconciliationReport: ReconciliationReport;
  endDate: Date;
}) => {
  return (
    <ReportContainer
      title={<FormattedMessage id="sales_summary.reconcile.analysis.no_discrepancies.title" />}
      withDiscrepancies={false}>
      <ReconciliationReportLineByLine business={props.business} startDate={props.startDate} endDate={props.endDate} />
    </ReportContainer>
  );
};

const ReconciliationReportWithDiscrepancies = (props: {
  business: Business;
  startDate: Date;
  reconciliationReport: ReconciliationReport;
  endDate: Date;
}) => {
  return (
    <ReportContainer
      title={<FormattedMessage id="sales_summary.reconcile.help_center.discrepancyDetected.title" />}
      withDiscrepancies={true}>
      <PaymentsSourceOfDiscrepancies
        business={props.business}
        startDate={props.startDate}
        reconciliationReport={props.reconciliationReport}
      />
      <ReconciliationReportLineByLine business={props.business} startDate={props.startDate} endDate={props.endDate} />
    </ReportContainer>
  );
};


export const ReconciliationAnalysis = ({
  business,
  startDate,
  endDate,
}: {
  business: Business;
  startDate: Date;
  endDate: Date;
}) => {

  const { isLoading, data } = useReconciliationReport({
    business,
    startDate,
    endDate,
  });
  if (isLoading) return <CircularProgress />;
  if (!data) return <Typography>No data available</Typography>;

  const hasDiscrepancies = data.nonNotifiedPayments.length !== 0 || data.fastPaymentPdq.length !== 0;

  if (hasDiscrepancies) {
    return (
      <ReconciliationReportWithDiscrepancies
        business={business}
        startDate={startDate}
        reconciliationReport={data}
        endDate={endDate}
      />
    );
  }

  return (
    <ReconciliationReportWithoutDiscrepancies
      business={business}
      startDate={startDate}
      reconciliationReport={data}
      endDate={endDate}
    />
  );
};

