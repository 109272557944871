import { useFormContext } from 'react-hook-form';
import { Box, FormControl, FormGroup, FormHelperText, Stack } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { Role } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/Role';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const ControlExtraRoles = ({ representativeIndex, businessCountryCode }: { representativeIndex: number, businessCountryCode: string }) => {
  const { formState: { errors } } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[representativeIndex]?.roles;
  const intl = useIntl();
  if (businessCountryCode !== 'GB') {
    return <></>;
  }
  return <FormControl component="fieldset">
    <Stack spacing={2}>
      <FormGroup>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Role role={'director'} representativeIndex={representativeIndex}
                label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.control.extraRole.director' })} />
        </Box>
      </FormGroup>
    </Stack>
    <FormHelperText error={!!error}>{!!error ? error?.message : ''}</FormHelperText>
  </FormControl>;
};
