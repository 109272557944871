import { Customization } from './domain/Customization';
import { Business } from 'src/business/domain/Business';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Panel, spaceUsages, Text, Title } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { PreviewContainer } from './components/Preview.style';
import LandingPreview from './components/LandingPreview';
import { LogoHandler } from './components/Pictures/LogoHandler';
import { CoverHandler } from './components/Pictures/CoverHandler';
import React from 'react';
import { Column } from 'src/sundayplus/Column';
import { pixels } from 'src/sundayplus/Size';
import { useForm } from 'react-hook-form';
import { Button, Stack } from '@mui/material';
import { useSaveShortWelcomeMutation } from './infrastructure/useSaveShortWelcomeMutation';
import { useSaveDescriptionMutation } from './infrastructure/useSaveDescriptionMutation';
import {
  LandingTextCustomization,
  LandingTextCustomizationFormData,
} from 'src/pages/Settings/Customization/components/LandingTextCustomization';


type CustomizationFormData = LandingTextCustomizationFormData & {};

export function LandingPageCustomization({
  customization,
  business,
  previewBackgroundColor,
  venueName,
}: {
  customization: Customization | undefined;
  business: Business;
  previewBackgroundColor: string | undefined;
  venueName: string;
}) {

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty, dirtyFields },
  } = useForm<CustomizationFormData>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      shortWelcome: customization?.shortWelcome ?? '',
      description: customization?.description ?? '',
    },
  });

  const intl = useIntl();

  const saveShortWelcomeMutation = useSaveShortWelcomeMutation();
  const saveDescriptionMutation = useSaveDescriptionMutation();

  async function onSubmit(inputs: CustomizationFormData) {
    if (dirtyFields.shortWelcome) await saveShortWelcomeMutation.mutateAsync({
      enrollmentId: business.patEnrollment!.id,
      shortWelcome: inputs.shortWelcome,
    });
    if (dirtyFields.description) await saveDescriptionMutation.mutateAsync({
      enrollmentId: business.patEnrollment!.id,
      description: inputs.description,
    });
    reset(inputs, { keepDirty: false });
  }

  return (
    <Panel flexDirection="column">
      <Column gap={pixels(16)}>
        <Title size="title4" marginBottom={spaceUsages.mediumLarge} keepCase>
          <FormattedMessage id="settings.app_settings.app_design.title" />
        </Title>

        <Text size="small" color={colorUsage.tertiaryText} keepCase>
          <FormattedMessage id="settings.customization.landing_images.description" />
        </Text>

        <Panel flexDirection="column" marginTop={spaceUsages.small} background={colorUsage.clearBackground}>
          <PreviewContainer>
            <LandingPreview
              coverUrl={customization?.cover?.m}
              logoUrl={customization?.logo?.logoUrls?.noResize}
              previewBackgroundColor={previewBackgroundColor}
              shortWelcome={customization?.shortWelcome ? customization?.shortWelcome : `${intl.formatMessage({ id: 'settings.customization.landing_page.welcome_to' })} ${venueName}`}
              description={customization?.description ? customization.description : ''}
            />
          </PreviewContainer>
        </Panel>

        <div>
          <Text size="small" color={colorUsage.tertiaryText}>
            <FormattedMessage id="settings.app_settings.app_design.appearance.logo" />
          </Text>
          <LogoHandler venueId={business.patEnrollment?.id!} />
        </div>

        <div>
          <Text size="small" color={colorUsage.tertiaryText}>
            <FormattedMessage id="settings.app_settings.app_design.appearance.cover" />
          </Text>
          <CoverHandler venueId={business.patEnrollment?.id!} />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <LandingTextCustomization register={register} />
            <Button type="submit" variant="contained" size={'small'} disabled={!isValid || isSubmitting || (!isDirty)}
                    sx={{ alignSelf: 'end' }}
            >
              <FormattedMessage id="settings.save_changes" />
            </Button>
          </Stack>
        </form>
      </Column>
    </Panel>
  );
}
