import { CurrencyCode, Money } from '@sundayapp/web-money';
import { Table } from 'src/floorplan/domain/Table';
import { Refund as RefundResult } from '../../domain/payment/Refund';
import {
  CardBrand,
  Payment,
  PaymentForOrdering,
  PaymentForPat,
  PaymentForPDQ,
  PaymentMethodType,
  PaymentStatuses,
  PosStatus,
  Refund,
  RefundStatuses,
  SpecificPayment,
  Voucher,
} from '../domain/Payment';
import { PaymentPort } from '../domain/PaymentPort';
import {
  MoneyResponse,
  PaymentRepositoryHttp,
  PaymentResponse as PaymentResponseHttp,
  RefundResponse,
  VoucherResponse,
} from './PaymentRepositoryHttp';
import { OrderingRepositoryHttp } from './OrderingRepositoryHttp';
import { OrderSummary } from '../domain/OrderSummary';
import { PaymentAccount } from '../domain/PaymentAccount';
import { mapPaymentResponse } from './Mapper';
import {
  PatOrchestratorPaymentResponse,
  PatOrchestratorRepositoryHttp,
  PatOrchestratorTabResponse,
} from './PatOrchestratorRepositoryHttp';
import { EnrollmentForPayment } from '../domain/EnrollmentForPayment';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Business, BusinessId } from 'src/business/domain/Business';
import { PaymentProductOrigin, paymentProductOrigins } from '../domain/PaymentProductOrigin';
import { useListTables } from 'src/floorplan/queries/listTables';
import { datadogLogs } from '@datadog/browser-logs';

const moneyFrom = (moneyResponse: MoneyResponse): Money => ({
  amount: moneyResponse.amount,
  currency: moneyResponse.currency as CurrencyCode,
});

const paymentStatusFrom = (status: string): PaymentStatuses => {
  switch (status) {
    case 'Succeeded':
      return 'succeeded';
    case 'Failed':
      return 'failed';
    case 'Canceled':
      return 'canceled';
    case 'ActionRequired':
      return 'action_required';
    case 'PosAcknowledgementRequired':
    case 'Created':
      return 'pending';
    case 'Authorized':
      return 'authorized';
    default:
      return 'unspecified';
  }
};

const paymentMethodTypeFrom = (paymentMethodType: string): PaymentMethodType => {
  switch (paymentMethodType) {
    case 'SAVED_CARD':
    case 'CARD':
      return 'card';
    case 'BANCONTACT':
      return 'bancontact';
    case 'CONECS':
      return 'conecs';
    case 'BIMPLI':
      return 'bimpli';
    case 'SWILE':
      return 'swile';
    case 'PASS_RESTAURANT':
      return 'pluxee';
    case 'TICKET_SERVICOS':
      return 'ticket serviços';
    case 'IDEAL':
      return 'ideal';
    case 'UP':
      return 'up';
    case 'METHOD_EDENRED':
      return 'edenred';
    case 'CHEQUE_VACANCES':
      return 'cheque_vacances';    
    default:
      return 'card';
  }
};

const refundStatusFrom = (status: string): RefundStatuses => {
  switch (status) {
    case 'SUCCEEDED':
      return 'succeeded';
    case 'FAILED':
    case 'CANCELED':
      return 'failed';
    case 'CREATED':
    case 'PENDING':
      return 'pending';
    default:
      return 'unspecified';
  }
};

const refundFrom = (refundResponse: RefundResponse): Refund => ({
  id: refundResponse.refundId,
  amount: moneyFrom(refundResponse.amount),
  status: refundStatusFrom(refundResponse.status),
  timestamp: refundResponse.createdAt,
  comment: refundResponse.description,
});

const voucherFrom = (voucherResponse: VoucherResponse | undefined): Voucher | undefined => {
  if (voucherResponse == null) {
    return undefined;
  }
  return {
    code: voucherResponse.code,
    amount: moneyFrom(voucherResponse.amount),
  };
};

const fixNullTextValues = (value: string | undefined): string | undefined => (value === 'null' ? undefined : value);

const groupOrderDisplayIdsByOrderId = (acc: Record<string, string>, order: OrderSummary): Record<string, string> => {
  acc[order.id] = order.displayId;
  order.regroupedOrderSummaries.forEach((regroupOrder) => {
    acc[regroupOrder.id] = order.displayId;
  });
  return acc;
};

const tableNumberFrom = (tables: Table[], tableId?: string, tableNumber?: string): string | undefined => {
  const foundTable = tables.find((table) => table.id === tableId);
  return foundTable?.number ?? tableNumber ?? tableId;
};

const displayIdFrom = (
  tabs: PatOrchestratorTabResponse[],
  payments: PatOrchestratorPaymentResponse[],
  paymentId: string,
): string | undefined => {
  const foundTab = tabs.find((tab) => tab.paymentId === paymentId);
  return foundTab?.displayId ?? payments.find((payment) => payment.id === paymentId)?.bill.tab?.displayId;
};

const userNameFrom = (
  tabs: PatOrchestratorTabResponse[],
  payments: PatOrchestratorPaymentResponse[],
  paymentId: string,
): string | undefined => {
  const foundTab = tabs.find((tab) => tab.paymentId === paymentId);
  return foundTab?.customerFullName ?? payments.find((payment) => payment.id === paymentId)?.bill.tab?.customerFullName;
};

const posStatusFrom = (payments: PatOrchestratorPaymentResponse[], paymentId: string): PosStatus | undefined => {
  const payment = payments.find((p) => p.id === paymentId);
  if (payment) {
    switch (payment.state) {
      case 'ENGAGED':
      case 'NOTIFIED':
      case 'REFUSED':
      case 'REGISTERED':
      case 'SUCCEEDED':
      case 'UNFULFILLED':
        return {
          status: payment.state,
          cause: payment.cause,
        };
      default:
        return undefined;
    }
  }
  return undefined;
};

const paymentFrom = (payment: PaymentResponseHttp) =>
  new Payment(
    payment.paymentId,
    payment.metadata.order_id,
    payment.metadata.tab_id,
    moneyFrom(payment.totalAmount),
    moneyFrom(payment.tipsAmount),
    moneyFrom(
      payment.digitalFeeAmount ?? {
        amount: 0,
        currency: payment.totalAmount.currency,
      },
    ),
    paymentStatusFrom(payment.status),
    payment.processedAt,
    payment.refunds.map(refundFrom),
    payment.paymentProvider,
    paymentMethodTypeFrom(payment.paymentMethodType),
    payment.metadata,
    payment.failDetails,
    payment.paymentMethodReference,
    payment.cardBrand as CardBrand,
    voucherFrom(payment.voucher),
    fixNullTextValues(payment.metadata.serialNumber),
    fixNullTextValues(payment.metadata.waiterName),
  );

const mapTableId = (tableId?: string) =>
  tableId && tableId !== '00000000-0000-0000-0000-000000000000' ? tableId : undefined;

function tabIdFrom(tabs: PatOrchestratorTabResponse[], payments: PatOrchestratorPaymentResponse[], paymentId: string) {
  const foundTab = tabs.find((tab) => tab.paymentId === paymentId);
  return foundTab?.tabId ?? payments.find((payment) => payment.id === paymentId)?.bill.tab?.tabId;
}

export class PaymentAdapter implements PaymentPort {
  constructor(
    private paymentRepositoryHttp: PaymentRepositoryHttp,
    private listTables: ReturnType<typeof useListTables>,
    private orderingRepositoryHttp: OrderingRepositoryHttp,
    private patOrchestratorRepositoryHttp: PatOrchestratorRepositoryHttp,
  ) {
  }

  async getPayments(business: Business, startDate: Date, endDate: Date, origins: PaymentProductOrigin[]): Promise<SpecificPayment[]> {
    const allPayments = await this.paymentRepositoryHttp.list(business.id, startDate, endDate).then((response) => response.map(paymentFrom));
    const paymentsByOrigin = this.groupByOrigin(allPayments);
    const classifiedPayments: SpecificPayment[] = [];

    if (origins.includes(paymentProductOrigins.PAT)) {
      const patPayments = await this.getPaymentsForPat(business, paymentsByOrigin[paymentProductOrigins.PAT]);
      classifiedPayments.push(...patPayments);
    }

    if (origins.includes(paymentProductOrigins.OP)) {
      const orderingPayments: SpecificPayment[] = await this.getPaymentsForOrdering(business, startDate, endDate, paymentsByOrigin[paymentProductOrigins.OP]);
      classifiedPayments.push(...orderingPayments);
    }

    if (origins.includes(paymentProductOrigins.TPE)) {
      const pdqPayments: SpecificPayment[] = await this.getPdqPayments(paymentsByOrigin[paymentProductOrigins.TPE]);
      classifiedPayments.push(...pdqPayments);
    }

    const otherOriginsPayments: SpecificPayment[] = [
      paymentsByOrigin[paymentProductOrigins.PAYMENT_LINK],
      paymentsByOrigin[paymentProductOrigins.UNKNOWN],
      paymentsByOrigin[paymentProductOrigins.SMB],
    ].flatMap((payments) => payments.map((payment) => ({ type: 'unknown', payment })));


    return classifiedPayments.concat(otherOriginsPayments);
  }

  private groupByOrigin(payments: Payment[]): { [key in PaymentProductOrigin]: Payment[] } {
    return payments.reduce((acc: { [key in PaymentProductOrigin]: Payment[] }, payment) => {
      const origin = (payment.metadata.origin as PaymentProductOrigin | undefined) || paymentProductOrigins.UNKNOWN;
      return ({
        ...acc,
        [origin]: acc[origin].concat(payment),
      });
    }, {
      [paymentProductOrigins.OP]: [],
      [paymentProductOrigins.PAT]: [],
      [paymentProductOrigins.TPE]: [],
      [paymentProductOrigins.PAYMENT_LINK]: [],
      [paymentProductOrigins.SMB]: [],
      [paymentProductOrigins.UNKNOWN]: [],
    });
  }

  private async getPaymentsForPat(business: Business, payments: Payment[]): Promise<PaymentForPat[]> {

    const tables: Table[] = (this.listTables.isFetched
      ? this.listTables
      : await this.listTables.refetch()).data || [];

    const paymentIds = payments.map((p) => p.id);
    const [billingPayments, billingTabs] = await Promise.all([
      this.patOrchestratorRepositoryHttp.searchPayments(business.id, paymentIds),
      this.patOrchestratorRepositoryHttp.searchTabsByPaymentIds(business.id, paymentIds),
    ]);

    return payments
      .map((payment: Payment) => {
        const tableId = mapTableId(payment.metadata.table_id);
        const tableNumber = fixNullTextValues(payment.metadata.tableNumber);
        payment.waiterName = billingPayments.find((p) => p.id === payment.id)?.bill.staffName;
        return {
          type: 'pay-at-table',
          payment,
          tableId,
          tabId: tabIdFrom(billingTabs, billingPayments, payment.id),
          tabDisplayId: displayIdFrom(billingTabs, billingPayments, payment.id),
          userName: userNameFrom(billingTabs, billingPayments, payment.id),
          tableNumber: tableNumberFrom(tables, tableId, tableNumber),
          posStatus: posStatusFrom(billingPayments, payment.id),
        };
      });
  }

  private async getPaymentsForOrdering(business: Business, startDate: Date, endDate: Date, payments: Payment[]): Promise<PaymentForOrdering[]> {
    const orderResults = await this.orderingRepositoryHttp.getOrders(business.oapEnrollment!.id, startDate, endDate);
    const orderDisplayIdsByOrderId = orderResults.reduce(groupOrderDisplayIdsByOrderId, {});

    return payments
      .map(
        (payment: Payment) =>
          ({
            type: 'ordering',
            payment,
            orderDisplayId: orderDisplayIdsByOrderId[payment.metadata.order_id!],
          }),
      );
  }

  private async getPdqPayments(payments: Payment[]): Promise<PaymentForPDQ[]> {
    return payments.map((payment: Payment) => ({
      type: 'pdq',
      payment,
      tableNumber: payment.metadata.tableNumber,
    }));
  }

  generateConfigurationURL(businessId: BusinessId, refreshUrl: string, returnUrl: string): Promise<string> {
    return this.paymentRepositoryHttp.generateConfigurationURL(businessId, refreshUrl, returnUrl);
  }

  refund(payment: Payment, amount: number, description?: string): Promise<RefundResult> {
    return this.paymentRepositoryHttp.refund(payment, amount, description);
  }

  cancelPayment(paymentId: string): Promise<void> {
    return this.paymentRepositoryHttp.cancel(paymentId);
  }

  private downloadFile = (fileName: string, blob: Blob) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );
    document.body.removeChild(link);
  };

  downloadReceiptForOrdering = async (orderId: string, fileName: string) => {
    try {
      const receipt = await this.orderingRepositoryHttp.getReceipt(orderId);
      this.downloadFile(fileName, receipt);
    } catch (error) {
      datadogLogs.logger.error(`could not download receipt for orderId=${orderId}`);
      throw error;
    }
  };

  getAccount = (enrollmentId: EnrollmentId): Promise<PaymentAccount> => this.paymentRepositoryHttp.account(enrollmentId).then((response) => mapPaymentResponse(response));

  getEnrollment(enrollmentId: EnrollmentId): Promise<EnrollmentForPayment> {
    return this.paymentRepositoryHttp.getEnrollment(enrollmentId);
  }
}
