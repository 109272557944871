import React, { useEffect, useState } from 'react';
import { NoPos } from 'src/pages/NoPos/NoPos';
import {
  Alert,
  Box,
  Drawer,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage, FormattedTime, useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate, useParams } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { tipsStaffRulesPath } from 'src/app/navigation/pathHelpers';
import { useGetWaiter } from '../queries/getWaiterQuery';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { UpdateEnrollmentTippingConfiguration } from 'src/tips/page/UpdateEnrollmentTippingConfiguration';
import { Business } from 'src/business/domain/Business';
import { isAllowedForDirectTippingConfiguration, sourceFilters, SourceFilterValue } from 'src/tips/domain/Tips';
import { ServiceType } from 'src/domain/venue/ServiceType';
import PdqSvg from 'src/app/component/icons/PdqSvg';
import QrCodesSvg from 'src/app/component/icons/QrCodesSvg';
import { useGetDirectTippingBusinessConfiguration } from 'src/tips/queries/getDirectTippingBusinessConfigurationQuery';

const DRAWER_WIDTH = 592;

const CloseButton = styled('div')`
  cursor: pointer;
  margin-top: 32px;
  margin-left: 32px;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    backgroundColor: '#F6F6F9',
  },
}));

const defaultService = (business: Business) => (business.patEnrollment ? 'QR' : 'PDQ');

export const UpdateTippingConfiguration: React.FC = () => {
  const business = useCurrentBusinessOrThrow();
  const businessIsNoPos = useBusinessIsNoPos();
  const navigate = useNavigate();
  const theme = useTheme();
  const intl = useIntl();
  const { waiterId } = useParams();
  const { status: statusWaiter, data: dataWaiter } = useGetWaiter(business.id, waiterId);
  const { status: statusTippingConfiguration, data: dataTippingConfiguration } =
    useGetDirectTippingBusinessConfiguration(business.id);
  const [patRate, setPatRate] = useState<number | undefined>(undefined);
  const [pdqRate, setPdqRate] = useState<number | undefined>(undefined);
  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(true);
  const [sourceFilter, setSourceFilter] = useState<SourceFilterValue>(defaultService(business));

  useEffect(() => {
    if (statusWaiter === 'success') {
      if (business.patEnrollment) {
        setPatRate(dataWaiter?.waiter.rates[business.patEnrollment.id]);
      }
      if (business.pdqEnrollment) {
        setPdqRate(dataWaiter?.waiter.rates[business.pdqEnrollment.id]);
      }
    }
  }, [statusWaiter]);

  useEffect(() => {
    if (statusTippingConfiguration === 'success') {
      setSourceFilter(business.patEnrollment && dataTippingConfiguration[business.patEnrollment.id] ? 'QR' : 'PDQ');
    }
  }, [statusTippingConfiguration]);

  if (businessIsNoPos) return <NoPos />;
  if (!waiterId || !dataWaiter || !dataTippingConfiguration) return <></>;

  const closeDrawer = () => {
    setIsDrawerOpened(false);
    navigate(tipsStaffRulesPath(business.id));
  };

  const displayRate = (currentRate: number | undefined) => {
    if (currentRate === undefined || currentRate === 0) {
      return intl.formatMessage({ id: 'direct_tipping.disabled' });
    } else {
      return `${currentRate / 100}%`;
    }
  };

  const displaySourceRate = (serviceTypes: ServiceType[]) =>
    serviceTypes.includes(ServiceType.PAY_AT_TABLE) ? displayRate(patRate) : displayRate(pdqRate);

  const getIcon = (serviceTypes: ServiceType[], selected: boolean) => {
    const color = selected ? 'black' : 'grey';
    return serviceTypes.includes(ServiceType.PAY_AT_TABLE) ? <QrCodesSvg color={color} /> : <PdqSvg color={color} />;
  };

  const availableSourceFilters = Object.values(sourceFilters).filter(
    isAllowedForDirectTippingConfiguration(business, dataTippingConfiguration),
  );

  const onSourceFilterChanged = (event: React.MouseEvent<HTMLElement>, newSourceValue: string | null) => {
    setSourceFilter(newSourceValue === null ? defaultService(business) : newSourceValue);
  };

  const drawerContent = () => (
    <Box>
      <Box mb={'30px'}>
        <CloseButton onClick={() => closeDrawer()}>
          <CloseIcon />
        </CloseButton>
      </Box>
      <Typography variant="h3" sx={{ letterSpacing: '-3px' }} ml={4} mb={1} color={theme.palette.text.primary}>
        <FormattedMessage id="direct_tipping.rate_drawer" defaultMessage="Configure instant tipping" />
      </Typography>
      <Alert
        severity="info"
        sx={{
          marginTop: 4,
          paddingLeft: 4,
          paddingRight: 4,
          borderRadius: 0,
          '& .MuiAlert-message': {
            justifyContent: 'space-between',
          },
        }}
      >
        <Box>
          <FormattedMessage
            id="direct_tipping.current_configuration"
            defaultMessage={`Current ${dataWaiter.waiter.name}'s instant tipping configuration`}
            values={{ waiter: dataWaiter.waiter.name }}
          />
        </Box>
        {dataWaiter.lastUpdate && (
          <Box sx={{ color: 'grey' }}>
            <FormattedMessage id="direct_tipping.updated_by" defaultMessage="by" /> {dataWaiter.lastUpdate.triggeredBy}
            {' - '}
            <FormattedTime
              value={dataWaiter.lastUpdate.updatedAt}
              month="long"
              day="numeric"
              weekday="long"
              year="numeric"
              hour="2-digit"
              minute="2-digit"
            />
          </Box>
        )}
      </Alert>
      <Box m={3}>
        <StyledToggleButtonGroup
          fullWidth
          value={sourceFilter}
          exclusive
          onChange={onSourceFilterChanged}
          disabled={availableSourceFilters.length <= 1}
        >
          {availableSourceFilters.map((source) => (
            <ToggleButton key={source.value} value={source.value}>
              <Box mt={'4px'} mr={1}>
                {getIcon(source.displayFor, sourceFilter === source.value)}
              </Box>
              <FormattedMessage id={source.translations} /> - {displaySourceRate(source.displayFor)}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Box>
      <UpdateEnrollmentTippingConfiguration
        waiter={dataWaiter.waiter}
        business={business}
        serviceType={sourceFilter === 'QR' ? ServiceType.PAY_AT_TABLE : ServiceType.PAYMENT_TERMINAL}
        closeDrawer={closeDrawer}
      />
    </Box>
  );

  return (
    <>
      {isDrawerOpened && (
        <Drawer anchor="right" open={isDrawerOpened} onClose={closeDrawer} PaperProps={{ sx: { width: DRAWER_WIDTH } }}>
          {drawerContent()}
        </Drawer>
      )}
    </>
  );
};
