// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { Box, Grid, Theme, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useLocation, useNavigate } from 'react-router';
import { DateRange, RevenuePeriod, revenueTabDatePresets } from '../../../domain/RevenuesDatePresets';
import { URL_DATE_FORMAT } from 'src/components/time-frame-filter/TimeFrameFilter';

export const DatesTooltip = ({
  dateRange,
  timezone,
  datesWithTimezoneAlready,
}: { dateRange: DateRange; timezone: string; datesWithTimezoneAlready: boolean }) => {
  const formattedTimezone = moment()
    .tz(timezone)
    .zoneAbbr();

  const formatDate = datesWithTimezoneAlready ?
    ((date: Date) => date.toLocaleDateString(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })) :
    (date: Date) => date.toLocaleDateString(navigator.language, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: timezone,
    });

  const startDateDisplay = formatDate(dateRange.startDate.toDate());
  const endDateDisplay = formatDate(dateRange.endDate.toDate());

  return (
    <Box
      p={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <Typography sx={{ color: '#BDBDBD' }} variant="caption">
          <FormattedMessage
            id="settings.shift.from.label"
            defaultMessage="from"
          />
          :
        </Typography>
        <Typography color="white" variant="caption">
          {startDateDisplay}
          {' '}
          (
          {formattedTimezone}
          )
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <Typography sx={{ color: '#BDBDBD' }} variant="caption">
          <FormattedMessage
            id="settings.shift.to.label"
            defaultMessage="to"
          />
          :
        </Typography>
        <Typography color="white" variant="caption">
          {endDateDisplay}
          {' '}
          (
          {formattedTimezone}
          )
        </Typography>
      </Box>
    </Box>
  );
};
export const RevenuesDates = ({
  period,
  timezone,
  theme,
}: { period: RevenuePeriod, timezone: string, theme: Theme }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const formatDate = (date: Date) => ({
    date: date.toLocaleDateString(navigator.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: timezone,
    }),
    time: date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: timezone,
    }),
  });
  const buildEndDateDisplay = () => {
    const endDateCopy = period.dateRange.endDate.clone();
    const endDate = period.filter === revenueTabDatePresets.CUSTOM ? endDateCopy : endDateCopy.subtract(1, 'day');
    return formatDate(endDate.toDate());
  };

  const startDateDisplay = formatDate(period.dateRange.startDate.toDate());
  const endDateDisplay = buildEndDateDisplay();
  const isEndDateToday = () => period.dateRange.endDate.diff(moment()
    .tz(timezone)) > 0;
  const isSameDay = startDateDisplay.date === endDateDisplay.date;

  const selectPeriod = useCallback((relative: 'previous' | 'next') => {
    const startDate = period.dateRange.startDate.clone();
    const endDate = period.dateRange.endDate.clone();

    if (relative === 'previous') {
      startDate.subtract(1, 'day');
      endDate.subtract(1, 'day');
    } else {
      startDate.add(1, 'day');
      endDate.add(1, 'day');
    }
    navigate(`${location.pathname}?preset=${revenueTabDatePresets.CUSTOM}&startDate=${startDate.format(URL_DATE_FORMAT)}&endDate=${endDate.format(URL_DATE_FORMAT)}`);
  }, [period]);

  const formattedTimezone = moment()
    .tz(timezone)
    .zoneAbbr();

  return (
    <Grid
      mt={5}
      p={3}
      sx={{
        backgroundColor: '#FAFAFA',
        borderRadius: '16px 16px 0 0',
        borderBottom: '1px solid #dddde0',
      }}
      container
    >
      <Grid
        item
        xs={2}
        md={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <ChevronLeftIcon
          fontSize="large"
          sx={{
            color: theme.palette.text.secondary,
            marginRight: '50px',
            cursor: 'pointer',
          }}
          onClick={() => selectPeriod('previous')}
        />
      </Grid>
      <Grid
        item
        xs={8}
        md={6}
        sx={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >

        <Box sx={{
          display: 'flex',
          gap: '16px',
        }}
        >

          <Box sx={{ display: 'flex' }} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="body1" color={theme.palette.text.secondary}>{startDateDisplay.date}</Typography>
            <Box>
              <Typography variant="body2"
                          color={theme.palette.text.secondary}>{`${startDateDisplay.time} (${formattedTimezone})`} {isSameDay ? `- ${endDateDisplay.time} (${formattedTimezone})` : ''}</Typography>
            </Box>
          </Box>
          {!isSameDay && (
            <>
              {' '}
              <Typography variant="body1" color={theme.palette.text.secondary}>
                -
              </Typography>

              <Box sx={{ display: 'flex' }} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="body1" color={theme.palette.text.secondary}>{endDateDisplay.date}</Typography>
                <Box>
                  <Typography variant="body2"
                              color={theme.palette.text.secondary}>{endDateDisplay.time} {`(${formattedTimezone})`}</Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={2}
        md={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        {!isEndDateToday() && (
          <ChevronRightIcon
            fontSize="large"
            sx={{
              color: theme.palette.text.secondary,
              marginLeft: '50px',
              cursor: 'pointer',
            }}
            onClick={() => selectPeriod('next')}
          />
        )}
      </Grid>

    </Grid>
  );
};
