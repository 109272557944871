import { Box } from '@mui/material';

type Props = {
  url: string | undefined;
};

export function MenuImage({ url }: Props) {
  return <Box sx={{
    width: '87px',
    height: '52px',
    borderRadius: '12px',
    background: '#F6F6F9',
    border: '1px solid #DDD',
    boxSizing: 'border-box',
    overflow: 'hidden',
    flexShrink: 0,
  }}>
    {url && (
      <img src={url} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    )}
  </Box>;

}

