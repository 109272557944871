import { CardBrand, PaymentMethodRef } from 'src/payments/domain/Payment';
import { Stack } from '@mui/material';
import { CardBrandIcon } from 'src/payments/components/CardBrandIcon';
import * as React from 'react';

export const PaymentMethodCell = ({ cardBrand, cardLast4Digits }: {
  cardBrand?: CardBrand,
  cardLast4Digits?: PaymentMethodRef,
}) => {
  return (
    <Stack height={'100%'} direction={'row'} gap={1.5} alignItems={'center'}>
      <CardBrandIcon brand={cardBrand ?? ''} />
      {cardLast4Digits && `**** ${cardLast4Digits}`}
    </Stack>
  );
};
