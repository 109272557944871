import React, { ReactNode } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

const Title = ({ children, color }: { children: ReactNode; color: string }) => {
  return (
    <Typography variant={'h6'} color={color} fontWeight={500}>
      {children}
    </Typography>
  );
};
export const ReportContainer = ({ title, children, withDiscrepancies }: {
  title: ReactNode
  children: ReactNode;
  withDiscrepancies: boolean
}) => {
  const theme = useTheme();
  const backgroundColor = withDiscrepancies ? theme.palette.warning.light : theme.palette.info.light;
  const textColor = withDiscrepancies ? theme.palette.warning.dark : theme.palette.info.dark;
  return (
    <Stack borderRadius={2} bgcolor={backgroundColor} padding={2} spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        {withDiscrepancies ? (
          <WarningIcon fontSize="large" sx={{ color: textColor }} />
        ) : (
          <InfoIcon fontSize="large" sx={{ color: textColor }} />
        )}
        <Title color={textColor}>
          {title}
        </Title>
      </Stack>
      {children}
    </Stack>
  );
};
