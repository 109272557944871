import React, { FunctionComponent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Divider, ListItem, ListItemButton, styled } from '@mui/material';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { BusinessSelectionItem } from './BusinessSelectionItem';
import DoubleChevronSvg from '../../app/component/icons/DoubleChevronSvg';
import { switchVenue, venueHomePath } from 'src/app/navigation/pathHelpers';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import { BusinessSelectorContainer } from './BusinessSelectorContainer';
import { BusinessSearchBar } from 'src/app/navigation/navigationMenu/component/BusinessSearchBar';
import { BusinessId } from 'src/business/domain/Business';
import { multiLocationsEntry, MultilocationsEntry } from 'src/multi-locations/MultilocationsEntry';
import { MultilocationsSelectionItem } from 'src/multi-locations/MultilocationsSelectionItem';
import { useBusinessId } from 'src/business/hooks/useBusinessId';
import { useBusinessQuery } from 'src/business/hooks/useBusinessQuery';
import { hasAccessToMultiLocations } from 'src/multi-locations/domain/User';
import { ampli } from 'src/ampli';
import { datadogRum } from '@datadog/browser-rum';
import { useQueries } from '@tanstack/react-query';
import { businessQuery } from 'src/business/queries/businessQuery';
import { FixedSizeList as _FixedSizeList, FixedSizeListProps, ListChildComponentProps } from 'react-window';

const useAllBusinesses = () => {
  const user = useAuthenticatedUserOrThrow();
  return useQueries({
    queries: user.claims.map((c) => businessQuery(c.businessId as BusinessId)),
  });
};
type CurrentBusinessProps = {
  openable: boolean;
};

const CurrentBusiness = styled('div')<CurrentBusinessProps>(({ openable }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '8px',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '8px',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: openable ? PaletteTheme.menu.hover : undefined,
  },
}));

const FixedSizeList = _FixedSizeList as unknown as FunctionComponent<FixedSizeListProps>;

const Businesses = styled(FixedSizeList)<FixedSizeListProps>({
  paddingTop: '16px',
  '& .MuiListItemButton-root:hover': {
    backgroundColor: PaletteTheme.menu.hover,
  },
});

const BusinessItem = styled(ListItemButton)`
  cursor: pointer;
  padding: 8px 8px;
  margin: 0 8px 4px 8px;
  border-radius: 8px;
`;

export type BusinessSelectionProps = {
  compact: boolean;
};

export const BusinessSelection = ({ compact }: BusinessSelectionProps) => {
  const user = useAuthenticatedUserOrThrow();
  const businessId = useBusinessId();
  const { data: currentBusiness } = useBusinessQuery(businessId as BusinessId);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const isOpenable = user.claims.length > 1 && !compact;
  const [nameFilter, setNameFilter] = useState<string>('');
  const isOnAMultiLocationsPage = !currentBusiness;
  const businesses = useAllBusinesses();
  const openBusinessList = () => {
    if (isOpenable) {
      setOpen(true);
    }
  };

  const closeBusinessList = () => {
    setOpen(false);
  };

  const changeFilter = (name: string) => {
    setNameFilter(name);
  };

  const isSuspendedBusinesses = (id: BusinessId) => {
    const business = businesses.find((b) => b.data?.id === id);
    if (!business) return false;
    return business.data!.enrollments.every((e) => e.status === 'SUSPENDED');
  };

  const businessEntries: Array<BusinessEntry | MultilocationsEntry> = [...user.claims]
    .map((claim) => ({ ...claim, type: 'business' as const }))
    .filter((c) => isOnAMultiLocationsPage || c.businessId !== currentBusiness.id)
    .filter((c) => nameFilter === '' || c.businessName.toLowerCase().includes(nameFilter.toLowerCase()))
    .sort((a, b) => a.businessName.localeCompare(b.businessName))
    .sort((a) => (isSuspendedBusinesses(a.businessId) ? 1 : -1))
    .map((c) => ({
      id: c.businessId,
      path: !isOnAMultiLocationsPage
        ? switchVenue(c.businessId, currentBusiness.id, location.pathname)
        : venueHomePath(c.businessId),
      label: c.businessName,
      type: 'business-entry',
    }));

  type Entry = BusinessEntry | MultilocationsEntry;

  const computeEntries = (shouldAddMultiLocationsEntry: boolean): Array<Entry> =>
    shouldAddMultiLocationsEntry ? [multiLocationsEntry, ...businessEntries] : businessEntries;

  const entries = computeEntries(hasAccessToMultiLocations(user) && !isOnAMultiLocationsPage);

  function RenderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const entry: Entry = entries[index];
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <BusinessItem
          key={entry.id}
          onClick={() => {
            closeBusinessList();
            ampli.locationSwitched({
              fromLocation: currentBusiness?.id ?? 'all-locations',
              toLocation: entry.id,
            });
            if (!currentBusiness && entry.id !== 'all-locations') {
              datadogRum.addAction('navigated_away_from_all_locations');
            } else if (!!currentBusiness && entry.id === 'all-locations') {
              datadogRum.addAction('navigated_to_all_locations');
            }
            return navigate(entry.path);
          }}
        >
          {entry.type === 'business-entry' ? (
            <BusinessSelectionItem name={entry.label} id={entry.id} isSuspended={isSuspendedBusinesses(entry.id)} />
          ) : (
            <MultilocationsSelectionItem />
          )}
        </BusinessItem>
      </ListItem>
    );
  }

  type BusinessEntry = {
    id: BusinessId;
    path: string;
    label: string;
    type: 'business-entry';
  };

  function getHeight() {
    return Math.min(400, entries.length * 40 + 16);
  }

  return (
    <div>
      <CurrentBusiness openable={isOpenable} onClick={openBusinessList}>
        {!isOnAMultiLocationsPage ? (
          <BusinessSelectionItem
            isCurrent
            name={currentBusiness.name}
            id={currentBusiness.id}
            isCompact={compact}
            iconSize={32}
            isSuspended={isSuspendedBusinesses(currentBusiness.id)}
          />
        ) : (
          <MultilocationsSelectionItem isCompact={compact} isCurrent iconSize={32} />
        )}

        {isOpenable && <DoubleChevronSvg color="#B4B4BB" />}
      </CurrentBusiness>
      <BusinessSelectorContainer open={open} onCloseVenue={closeBusinessList}>
        <ListItem sx={{ cursor: 'pointer' }} onClick={closeBusinessList}>
          {!isOnAMultiLocationsPage ? (
            <BusinessSelectionItem
              name={currentBusiness.name}
              id={currentBusiness.id}
              iconSize={32}
              isSuspended={isSuspendedBusinesses(currentBusiness.id)}
            />
          ) : (
            <MultilocationsSelectionItem iconSize={32} />
          )}
        </ListItem>
        <BusinessItem>
          <BusinessSearchBar
            onChange={(e: any) => {
              changeFilter(e.target.value);
            }}
            value={nameFilter}
          />
        </BusinessItem>
        <Divider sx={{ marginTop: '8px' }} />
        <Businesses height={getHeight()} width={360} itemSize={40} itemCount={entries.length}>
          {RenderRow}
        </Businesses>
      </BusinessSelectorContainer>
    </div>
  );
};
