// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';

import { DateRange } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { Translation } from 'src/accounting/common/translation';
import { datePresetKeys, datePresets } from 'src/components/time-frame-filter/datePresets';


export const paymentsListDatePresetsTypes = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  CUSTOM: 'CUSTOM',

} as const;

export type PaymentsListDatePreset = ValuesOf<typeof paymentsListDatePresetsTypes>;

export const paymentsListDatePresets: (timezone?: string, momentProvider?: () => Moment) => Record<PaymentsListDatePreset, {
  range: DateRange,
  translation: Translation
}> = (timezone = Intl.DateTimeFormat().resolvedOptions().timeZone, momentProvider = () => moment()) => {

  const {
    [datePresetKeys.TODAY]: today,
    [datePresetKeys.YESTERDAY]: yesterday,
    [datePresetKeys.CUSTOM]: custom,
  } = datePresets(timezone, momentProvider);

  return {
    [paymentsListDatePresetsTypes.TODAY]: today,
    [paymentsListDatePresetsTypes.YESTERDAY]: yesterday,
    [paymentsListDatePresetsTypes.CUSTOM]: custom,
  };
};
