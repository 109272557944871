import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { Box, Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'chartjs-adapter-moment';
import { AverageRatingData, isTimeFrameTooLong } from './AverageRatingsViewModel';
import dayjs from 'dayjs';

const maxValueBelowMinAverage = (averageRating: (number | undefined)[]) => {
  const minAverage = Math.min(...averageRating.flatMap((t) => (t === undefined ? [] : [t])));
  return Math.max(1, Math.floor((minAverage - 1) * 10) / 10);
};

export const AverageRatingsChart = ({ viewModel }: { viewModel: AverageRatingData }) => {
  const data = {
    datasets: [
      {
        data: viewModel.data.averageRating,
        tension: 0.2,
        borderColor: 'black',
        backgroundColor: 'black',
        pointRadius: 4,
      },
    ],
  };
  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    clip: {
      left: 5,
      top: 5,
      right: 5,
      bottom: 5,
    },
    plugins: {
      legend: { display: false },
      datalabels: {
        display: true,
        align: 'top',
        formatter: (value) => {
          if (isTimeFrameTooLong(viewModel)) {
            const shouldDisplay = dayjs(value.x)
              .date() % 3 === 0;
            return shouldDisplay ? value.y : '';
          }
          return value.y;
        },
        font: { size: 14 },
        color: 'black',
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            const date = new Date(tooltipItem[0].label);
            return date.toLocaleDateString();
          },
        },
      },
    },
    scales: {
      x: {
        min: viewModel.period.startDateI()
          .unixInMs(),
        max: viewModel.period.endDateI()
          .unixInMs(),
        grid: {
          lineWidth: 0,
        },
        type: 'time',
        time: {
          unit: 'day',

        },
        ticks: {
          stepSize: isTimeFrameTooLong(viewModel) ? 5 : 1,
        },
        offset: false,
      },
      y: {
        position: 'right',
        display: true,
        ticks: { stepSize: 1 },
        border: {
          color: 'white',
        },
        grid: {
          tickColor: 'white',
        },
        offset: true,
        max: 5,
        min: maxValueBelowMinAverage(viewModel.data.averageRating.map(({ y }) => y)),
      },
    },
  };

  return <Stack direction="column" gap={2}>
    <Box style={{
      position: 'relative',
      height: '30vh',
    }}
    >
      <Line
        options={options}
        data={data}
        plugins={[ChartDataLabels]}
      />
    </Box>
  </Stack>;
};
