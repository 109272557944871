import React, { useEffect } from 'react';
import { Avatar, Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import { alpha, darken, styled } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Menu } from '../../../domain/Menu';
import { useUpdateMenuMutation, useUpdateMenuPictureMutation } from '../../../mutations/menu/useMenuMutation';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import WarningAmberOutlined from 'src/app/component/icons/WarningAmberOutlined';
import { themeV5 } from 'src/app/theme/ThemeV5';
import CloseIcon from '@mui/icons-material/Close';
import fileSize from 'filesize';
import CheckIcon from '@mui/icons-material/Check';
import { UploadFileIcon } from 'src/ordering/common/components/Icons/UploadFileIcon';
import { PicturePicker } from 'src/ordering/common/components/PicturePicker/PicturePicker';
import { BusinessId } from 'src/business/domain/Business';
import { MenuPicturePicker } from 'src/ordering/common/components/PicturePicker/MenuPicturePicker';

type MenuImagePickerProps = {
  menu: Menu;
};

const ImageCard = styled(Card)`
  min-width: fit-content;
`;

const Title = styled('div')`
  margin-bottom: 24px;
`;

const Warning = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 1em 1em;
  align-items: center;
  border-radius: 0.25em;
  color: ${darken('#ED6C02', 0.6)};
  background-color: ${alpha('#ED6C02', 0.1)};
  gap: 0.75em;
`;

const PicturePickerWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${themeV5.palette.background.default};
  border: 1px dashed rgba(66, 66, 72, 0.14);
  border-radius: 4px;
  gap: 8px;
  height: fit-content;
  justify-content: center;
  align-self: stretch;
  padding: 8px;
`;

const UploadedImageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  height: 80px;
`;

const UploadedImage = ({ url }: { url: string }) => {
  return (
    <Box sx={{
      width: '127px',
      height: 'inherit',
      backgroundColor: themeV5.palette.background.default,
      boxSizing: 'border-box',
      overflow: 'hidden',
      flexShrink: 0,
    }}>
      <img src={url} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt={'menu image'} />
    </Box>
  );
};

const UploadIconContainer = styled(Avatar)`
  background-color: ${alpha(themeV5.palette.primary.main, 0.12)};
`;

const InfoContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: inherit;
  background-color: ${themeV5.palette.background.default};
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.06),
  0 1px 1px 0 rgba(0, 0, 0, 0.04),
  0 1px 3px 0 rgba(0, 0, 0, 0.04);
`;

const CheckIconAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  background-color: ${themeV5.palette.success.main};
`;

const ActionsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const DescriptionImage = ({ size, extension, addedOnTimestamp, imageUploaded, removeImage }: {
  size: number | undefined,
  extension: string,
  addedOnTimestamp: string | undefined,
  imageUploaded?: boolean,
  removeImage?: () => Promise<void>
}) => {
  const intl = useIntl();

  const addedOn = (timestamp: string, locale: string) => {
    const d = new Date(Number(timestamp));
    return d.toLocaleDateString(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <InfoContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          alignItems: 'center',
          minWidth: '165px',
        }}>
        <UploadIconContainer><UploadFileIcon /></UploadIconContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography color="textPrimary">{extension.toUpperCase()}</Typography>
            {size && (
              <Typography color="textPrimary">&nbsp;({fileSize(size, { output: 'string', round: 2 })})</Typography>)}
          </div>
          {addedOnTimestamp && (
            <div>
              <Typography color="textSecondary" style={{ fontSize: '14px' }}>
                {intl.formatMessage({ id: 'menus.settings.image.added-on' })} {addedOn(addedOnTimestamp, navigator.language)}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <ActionsContainer>
        <div style={{ padding: '12px' }}>
          <IconButton aria-label="close" onClick={removeImage} sx={{ height: 'fit-content' }}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        {imageUploaded && (
          <CheckIconAvatar><CheckIcon /></CheckIconAvatar>)}
      </ActionsContainer>
    </InfoContainer>
  );
};


interface PatMenuImagePickerProps {
  imageUploaded: boolean,
  businessId: BusinessId,
  uploadingImage: boolean,
  pictureHeight: number,
  pictureWidth: number,
  setImageUploaded: (imageUploaded: boolean) => void,
  menu: Menu,
  onImageChanged: (canvasBase64: string) => void
}

const PatMenuImagePicker = ({
  imageUploaded,
  businessId,
  uploadingImage,
  pictureHeight,
  pictureWidth,
  setImageUploaded,
  menu,
  onImageChanged,
}: PatMenuImagePickerProps) => {
  const updateMenu = useUpdateMenuMutation(businessId, menu.id);

  const [size, setSize] = React.useState<number | undefined>(undefined);

  const parseFileSize = async (url: string): Promise<number | undefined> => {
    try {
      return await fetch(url, { method: 'HEAD' })
        .then((response) => {
          const contentLength = response.headers.get('Content-Length');
          if (contentLength) {
            return parseInt(contentLength, 10);
          } else {
            return undefined;
          }
        });

    } catch (error) {
      return undefined;
    }
  };

  useEffect(() => {
    parseFileSize(menu.imageUrl).then(setSize);
  }, [menu.imageUrl]);

  const parseFileExtension = (url: string): string | undefined => {
    try {
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      const match = pathname.match(/\.([a-zA-Z0-9]+)$/);
      return match ? match[1] : undefined;
    } catch (error) {
      return undefined;
    }
  };

  const parseAddedOnTimestamp = (imageUrl: string): string | undefined => {
    try {
      const parsedUrl = new URL(imageUrl);
      const timestamp = parsedUrl.searchParams.get('timestamp');
      return timestamp ?? undefined;
    } catch (error) {
      return undefined;
    }
  };

  const removeImage = async () => {
    await updateMenu.mutateAsync({ imageUrl: '' });
    setImageUploaded(false);
  };

  return (
    <>
      {(!menu.imageUrl && !uploadingImage && !imageUploaded) && (
        <PicturePickerWrapper>
          <MenuPicturePicker
            imageUrl={menu.imageUrl}
            updating={false}
            onImageChanged={onImageChanged}
            width={pictureWidth}
            height={pictureHeight}
          />
        </PicturePickerWrapper>
      )}
      {(!uploadingImage && menu.imageUrl) && (
        <UploadedImageContainer>
          <UploadedImage url={menu.imageUrl} />
          <DescriptionImage size={size}
                            extension={parseFileExtension(menu.imageUrl) || 'png'}
                            addedOnTimestamp={parseAddedOnTimestamp(menu.imageUrl)}
                            imageUploaded={imageUploaded}
                            removeImage={removeImage}
          />
        </UploadedImageContainer>
      )}
    </>
  );
};

export const MenuImagePicker = ({
  menu,
}: MenuImagePickerProps) => {
  const business = useCurrentBusinessOrThrow();
  const [imageUploaded, setImageUploaded] = React.useState<boolean>(false);
  const [uploadingImage, setUploadingImage] = React.useState<boolean>(false);

  const updateMenuPicture = useUpdateMenuPictureMutation(business.id, menu.id);

  const onImageChanged = (canvasBase64: string) => {
    setUploadingImage(true);
    updateMenuPicture.mutateAsync(canvasBase64).then(() => {
      setImageUploaded(true);
      setUploadingImage(false);
    });
  };

  const pictureWidth = business.patEnrollment ? 480 : 288;
  const pictureHeight = business.patEnrollment ? 270 : 288;

  return (
    <ImageCard>
      <CardContent>
        <Title>
          <Typography variant="h6" component="div">
            <FormattedMessage id="menus.settings.image.label" defaultMessage="Menu image" />
          </Typography>
        </Title>
        <Warning>
          <WarningAmberOutlined />
          <Typography variant="body2">
            <FormattedMessage id="menus.settings.image.warning" />
          </Typography>
        </Warning>

        {business.patEnrollment ? (
          <PatMenuImagePicker
            imageUploaded={imageUploaded}
            businessId={business.id}
            menu={menu}
            uploadingImage={uploadingImage}
            setImageUploaded={setImageUploaded}
            pictureHeight={pictureHeight}
            pictureWidth={pictureWidth}
            onImageChanged={onImageChanged}
          />
        ) : (
          <PicturePickerWrapper>
            <PicturePicker
              imageUrl={menu.imageUrl}
              updating={false}
              onImageChanged={onImageChanged}
              width={pictureWidth}
              height={pictureHeight}
            />
          </PicturePickerWrapper>
        )}
      </CardContent>
    </ImageCard>
  );
};
